import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import Logo from '../../logo/Logo';
import { SvgIcon, Title } from '../../shared';

import styles from './Sidebar.module.css';
import BackToAppButton from '../BackToAppButton';
import { SidebarProps } from '../../../shared/data/types/components';
import { useConfig } from '../../shared/context/Config';

const Sidebar = ({
  isSidebarListVisible,
  hasBackButton,
  hasInnerShadow,
  radius,
  foregroundColor,
}: SidebarProps) => {
  const { t } = useTranslation(['sidebar', 'register_page']);
  const { config } = useConfig();

  let imageStyle = {};
  if (
    config.theme.components.sidebar.backgroundImage != null &&
    !hasBackButton
  ) {
    // show image only on login & register page
    imageStyle = {
      backgroundImage: `url(${config.theme.components.sidebar.backgroundImage})`,
      backgroundSize: 'cover',
      maxWidth: '50%',
    };
  }

  return (
    <div
      className={cx(styles.sidebar, {
        [styles.innerShadow]: hasInnerShadow,
      })}
      style={{
        backgroundColor: config.theme.colors.sidebar.background,
        color: config.theme.colors.sidebar.text,
        ...imageStyle,
      }}
    >
      <div className={styles.stickyWrapper}>
        <div className={styles.header}>
          <Logo />
        </div>
        {hasBackButton ? (
          <BackToAppButton
            foregroundColor={foregroundColor}
            radius={radius}
            isDesktop={true}
          />
        ) : null}
        {isSidebarListVisible ? (
          <div className={styles.contentWrapper}>
            <Title
              type="h2"
              customStyles={styles.title}
              textColor={foregroundColor}
            >
              {t(
                'register_page:the-passwordless-api-for-fast-growing-businesses'
              )}
            </Title>
            <ul
              className={styles.list}
              style={{ color: foregroundColor }}
              data-testid="sidebar-features-list"
            >
              <li className={styles.item}>
                <SvgIcon
                  name="check"
                  fillColor={config.theme.colors.secondary.positive}
                  style={{ width: '40px', marginTop: '2px' }}
                />
                <div className={styles.itemTextWrapper}>
                  <Title
                    type="h3"
                    customStyles={styles.itemTitle}
                    textColor={foregroundColor}
                  >
                    {t('register_page:reduce-code-complexity')}
                  </Title>
                  <p style={{ color: foregroundColor }}>
                    {t(
                      'register_page:taking-the-complexity-out-of-passwordless-and-multi-factor-authentication-was-our-main-goal-when-building-hanko-authentication'
                    )}
                  </p>
                </div>
              </li>
              <li className={styles.item}>
                <SvgIcon
                  name="check"
                  fillColor={config.theme.colors.secondary.positive}
                  style={{ width: '40px', marginTop: '2px' }}
                />
                <div className={styles.itemTextWrapper}>
                  <Title
                    type="h3"
                    customStyles={styles.itemTitle}
                    textColor={foregroundColor}
                  >
                    {t('register_page:fast-and-easy-integration')}
                  </Title>
                  <p style={{ color: foregroundColor }}>
                    {t(
                      'register_page:support-fido-bades-passwordless-mutli-factor-authentication-methods-on-a-website-or-app-normally-requires'
                    )}
                  </p>
                </div>
              </li>
              <li className={styles.item}>
                <SvgIcon
                  name="check"
                  fillColor={config.theme.colors.secondary.positive}
                  style={{ width: '40px', marginTop: '2px' }}
                />
                <div className={styles.itemTextWrapper}>
                  <Title
                    type="h3"
                    customStyles={styles.itemTitle}
                    textColor={foregroundColor}
                  >
                    {t('register_page:security-phishing-proof')}
                  </Title>
                  <p style={{ color: foregroundColor }}>
                    {t(
                      'register_page:hanko-exceeds-your-security-requirements-with-open-standards-based-biometric-authentication-methods'
                    )}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

Sidebar.defaultProps = {
  hasInnerShadow: false,
};

export default Sidebar;
