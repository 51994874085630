import styles from './Subtitle.module.css';

type Props = {
  children: string | React.ReactElement;
};

const Subtitle = ({ children }: Props) => {
  return <p className={styles.subtitle}>{children}</p>;
};

export default Subtitle;
