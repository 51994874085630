import { CSSProperties, useEffect, useState } from 'react';
import cx from 'classnames';
import { Components } from '../../../shared/data/types';
import { LoadingWheel, SvgIcon } from '../';

import styles from './Button.module.css';
import { useConfig } from '../context/Config';

const Button = ({
  ariaLabel,
  children,
  customStyles = '',
  dataTestid,
  isDisabled,
  disabledColor,
  icon,
  loading,
  dropdown,
  linkStyle,
  noStyle,
  onClick,
  onMouseEnter,
  onMouseOut,
  onFocus,
  onBlur,
  primary,
  radius,
  textColor,
  type,
}: Components.ButtonProps) => {
  const { config } = useConfig();
  const [hoverStyle, setHoverStyle] = useState<string | null>();
  const [dropdownStyle, setDropdownStyle] = useState<CSSProperties | undefined>(
    undefined
  );
  const [primaryStyle, setPrimaryStyle] = useState<CSSProperties | undefined>(
    undefined
  );
  const [secondaryStyle, setSecondaryStyle] = useState<
    CSSProperties | undefined
  >(undefined);
  const { button } = config.theme.components;
  const { brand } = config.theme.colors;

  useEffect(() => {
    setDropdownStyle({
      fontFamily: config.theme.fontFamily,
      backgroundColor: isDisabled
        ? disabledColor || button?.primary?.disabledColor
        : hoverStyle || config.theme.colors.brand.appBackground,
    });

    setPrimaryStyle({
      fontFamily: config.theme.fontFamily,
      backgroundColor: isDisabled
        ? config.theme.colors.brand.bright
        : config.theme.colors.brand.default,
      color: textColor || button?.primary?.text,
      borderRadius: radius || `${button?.primary?.radius}px`,
      transition: 'all 0.08s',
    });

    setSecondaryStyle({
      fontFamily: config.theme.fontFamily,
      backgroundColor: hoverStyle || config.theme.colors.secondary.light4,
      color: isDisabled
        ? config.theme.colors.secondary.light3
        : config.theme.colors.brand.text,
      borderRadius: radius || `${button?.secondary?.radius}px`,
      transition: 'all 0.08s',
    });
  }, [hoverStyle, isDisabled]);

  const linkButtonStyle = {
    fontFamily: config.theme.fontFamily,
    color: textColor || brand?.text,
  };

  const handleMouseEnter = () => {
    setHoverStyle(
      primary
        ? config.theme.colors.brand.bright
        : config.theme.colors.secondary.light4
    );
    onMouseEnter && onMouseEnter();
  };

  const handleMouseLeave = () => {
    setHoverStyle(null);
    onMouseOut && onMouseOut();
  };

  return (
    <button
      className={cx(
        styles.button,
        { [styles.disabled]: isDisabled },
        { [styles.linkStyle]: linkStyle },
        { [styles.noStyle]: noStyle },
        { [styles.dropdownStyle]: dropdown },
        customStyles
      )}
      onClick={isDisabled ? () => false : (e) => onClick(e)}
      aria-label={ariaLabel}
      data-testid={dataTestid}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      style={
        noStyle
          ? { color: textColor }
          : dropdown
          ? dropdownStyle
          : linkStyle
          ? linkButtonStyle
          : primary
          ? primaryStyle
          : secondaryStyle
      }
      type={type}
    >
      {icon ? (
        <div className={styles.iconWrapper}>
          <div className={styles.icon}>
            <SvgIcon
              name={icon}
              fillColor={
                primary ? button?.primary?.text : button?.secondary?.text
              }
            />
          </div>
          <hr className={styles.iconDivider} />
        </div>
      ) : null}
      {loading ? <LoadingWheel size="small" /> : children}
    </button>
  );
};

Button.defaultProps = {
  type: 'button',
};

export default Button;
