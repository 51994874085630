import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  AlertBox,
  Button,
  Form,
  // Link,
  Textbox,
  Title,
} from '../../../components/shared';
import { RouteContext } from '../../../components/shared/context';
import { EMAIL_PATTERN } from '../../../shared/data/constants';
import styles from './NewAccount.module.css';
import { useKratosFlow, useQuery } from '../../../shared/utils/hooks';
import { BaseError } from '../../../shared/data/errors/error';
import EmailVerification from '../email-verification/EmailVerification';
import { KRATOS_SESSION_WHOAMI } from '../../../shared/utils/apiConstants';
import TranslateError from '../../../components/shared/error/TranslateError';
// import { useConfig } from '../../../components/shared/context/Config';

const NewAccount = ({
  updateUserEmail,
}: {
  updateUserEmail: (email: string) => void;
}) => {
  const query = useQuery();
  const { t } = useTranslation(['register_page', 'common']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<BaseError | null>(null);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [isEmailVerificationSent, setIsEmailVerificationSend] =
    useState<boolean>(false);
  const {
    setNewFlow,
    newFlow,
    normalizeKratosFlow,
    initializeRegistrationFlow,
    initializeSettingsFlow,
    registrationFlow,
  } = useKratosFlow();
  const history = useHistory();
  const { setIsRouteRegister } = useContext(RouteContext);

  useEffect(() => {
    setIsRouteRegister(true);
    initializeRegistrationFlow(query.get('return_to'))
      .then((flow) => setNewFlow(normalizeKratosFlow(flow)))
      .catch((error: BaseError) => console.error(error));
  }, [setIsRouteRegister]);

  async function handleSubmit() {
    if (emailAddress.length > 0 && emailAddress.match(EMAIL_PATTERN)) {
      try {
        setIsLoading(true);
        setError(null);
        await registrationFlow({
          registrationFlow: newFlow,
          userEmail: emailAddress,
          firstName: firstName,
          lastName: lastName,
        });

        const hasSession = await fetch(
          `${process.env.REACT_APP_KRATOS_PUBLIC_URL}/${KRATOS_SESSION_WHOAMI}`,
          {
            method: 'GET',
            credentials: 'include',
          }
        );

        if (hasSession.ok) {
          const returnToUrl = query.get('return_to') || undefined;
          const settingsFlow = await initializeSettingsFlow(returnToUrl);
          history.push(`onboarding?flow=${settingsFlow.id}`);
        } else {
          setIsEmailVerificationSend(true);
        }

        updateUserEmail(emailAddress);
      } catch (error: any) {
        setError(error);
        setIsLoading(false);
      }
    }
  }

  return isEmailVerificationSent ? (
    <EmailVerification userEmail={emailAddress} />
  ) : (
    <>
      <Title customStyles={styles.title}>{t('create-new-account')}</Title>
      {error ? (
        <AlertBox type="error">
          <TranslateError error={error} />
        </AlertBox>
      ) : null}
      <Form onSubmit={handleSubmit}>
        <Textbox
          name="first-name"
          onChange={(e) => setFirstName(e.target.value)}
          hasFocus
          customStylesWrapper={styles.textbox}
          required
          label={t('first-name-0')}
          hideClearButton
          placeholder={t('your-first-name')}
          maxLength={64}
        />
        <Textbox
          name="last-name"
          onChange={(e) => setLastName(e.target.value)}
          customStylesWrapper={styles.textbox}
          required
          label={t('last-name-0')}
          placeholder={t('your-last-name')}
          hideClearButton
          maxLength={64}
        />
        <Textbox
          name="register-email"
          onChange={(e) => setEmailAddress(e.target.value)}
          type="email"
          customStylesWrapper={styles.textbox}
          required
          label={t('common:email')}
          hideClearButton
          placeholder={t('your-email')}
          maxLength={254}
        />
        <Button
          onClick={() => {}}
          type="submit"
          primary
          loading={isLoading}
          customStyles={styles.nextButton}
        >
          {t('common:next')}
        </Button>
      </Form>
      {/*<p*/}
      {/*  className={styles.tosWrapper}*/}
      {/*  style={{ color: config.theme.colors.brand.text }}*/}
      {/*>*/}
      {/*  {`${t('by-creating-an-account-you-agree-to-our')} `}*/}
      {/*  <span className={styles.tosLinkWrapper}>*/}
      {/*    <Link to="#" customStyles={styles.tosLink}>*/}
      {/*      {t('privacy-policy')}*/}
      {/*    </Link>*/}
      {/*    {` ${t('common:and')} `}*/}
      {/*    <Link to="#" customStyles={styles.tosLink}>*/}
      {/*      {t('terms-of-service')}*/}
      {/*    </Link>*/}
      {/*    .*/}
      {/*  </span>*/}
      {/*</p>*/}
    </>
  );
};

export default NewAccount;
