import { OAuth2Client } from '../data/types/oidc';

export const clientMatchesReturnTo = (
  returnTo: string | null,
  client: OAuth2Client
) => {
  if (!returnTo || client.client_uri === '') {
    return false;
  }

  let returnURL: URL;
  let clientURL: URL;

  try {
    returnURL = new URL(returnTo);
    clientURL = new URL(client.client_uri);
  } catch (error) {
    return false;
  }

  if (
    returnURL.protocol === clientURL.protocol &&
    (returnURL.protocol === 'http:' || returnURL.protocol === 'https:'
      ? returnURL.host === clientURL.host
      : true)
  ) {
    return true;
  }

  return (
    client.redirect_uris.filter((redirectUri: string) => {
      const allowedRedirectUrl = new URL(redirectUri);
      return (
        returnURL.protocol === allowedRedirectUrl.protocol &&
        (returnURL.protocol === 'http:' || returnURL.protocol === 'https:'
          ? returnURL.host === allowedRedirectUrl.host
          : true)
      );
    }).length > 0
  );
};
