import { Link, SvgIcon } from '../../../components/shared';
import { BackButtonProps } from '../../../shared/data/types/login';
import styles from './BackButton.module.css';
import { useConfig } from '../context/Config';

const BackButton = ({ dataTestid, text, to, onClick }: BackButtonProps) => {
  const { config } = useConfig();

  return (
    <Link
      to={to}
      dataTestid={dataTestid}
      customStyles={styles.wrapper}
      style={{ color: config.theme.colors.brand.text }}
      onClick={onClick}
    >
      <SvgIcon
        name="longArrowleft"
        fillColor={config.theme.colors.brand.text}
      />
      <span className={styles.email}>{text}</span>
    </Link>
  );
};

export default BackButton;
