// API
const API_URL = 'https://uuid.identity.dev.hanko.io/api';

// Kratos
const KRATOS_SESSION_WHOAMI = 'sessions/whoami';
const KRATOS_FLOW_LOGOUT = 'self-service/browser/flows/logout';
const KRATOS_SETTINGS = 'self-service/settings/browser';
const KRATOS_LOGIN = 'self-service/login/browser';
const KRATOS_RECOVERY = 'self-service/recovery/browser';
const KRATOS_REGISTRATION = 'self-service/registration/browser';

// Hydra
const HYDRA_LOGOUT = 'oauth2/sessions/logout';

export {
  API_URL,
  HYDRA_LOGOUT,
  KRATOS_SESSION_WHOAMI,
  KRATOS_FLOW_LOGOUT,
  KRATOS_SETTINGS,
  KRATOS_LOGIN,
  KRATOS_RECOVERY,
  KRATOS_REGISTRATION,
};
