import * as iconList from './icon-list';
import { IconsType } from '../../../shared/data/types';
import { useConfig } from '../context/Config';

type Props = {
  fillColor?: string;
  stroke?: string;
  style?: object;
  name: IconsType['name'];
  customStyles?: string;
};

const SvgIcon = ({ fillColor, name, stroke, style, customStyles }: Props) => {
  const { config } = useConfig();
  const Icon = iconList[name];

  return (
    <Icon
      fill={fillColor || config.theme.colors.brand.text}
      stroke={stroke}
      role="img"
      aria-hidden
      style={style}
      className={customStyles}
      data-testid={`icon-${name}`}
    />
  );
};

export default SvgIcon;
