import { useEffect, useRef } from 'react';
import { useConfig } from '../../../components/shared/context/Config';

type Props = {
  size?: number;
  lineWidth?: number;
  lineCap?: 'round' | 'butt' | 'square';
  velocity?: number;
  timerStartAt: Date | null;
  timerCallback: () => void;
};

const ProgressCircle = ({
  size = 18,
  lineWidth = 3,
  lineCap = 'butt',
  velocity = 0.02,
  timerStartAt,
  timerCallback,
}: Props) => {
  const { config } = useConfig();
  const ref = useRef<HTMLCanvasElement>(null);
  const rateLimitDuration = config.appSettings.passlinkRateLimitDuration;
  const fullAngle = Math.PI * 2;
  const halfSize = size / 2;
  const radius = halfSize - lineWidth;

  let currentAngle = 0;

  const drawAnimation = (ctx: CanvasRenderingContext2D, startedAt: Date) => {
    const now = new Date();
    const targetAngle =
      (fullAngle / (rateLimitDuration || 1)) *
      (now.getTime() - startedAt.getTime());

    initializeRenderingContext(ctx);
    drawBackgroundCircle(ctx);
    drawProgressCircle(ctx, currentAngle);

    if (
      currentAngle >= fullAngle ||
      now.getTime() > startedAt.getTime() + rateLimitDuration
    ) {
      timerCallback();
      return;
    }

    if (currentAngle < targetAngle) {
      currentAngle += velocity;
    }

    window.requestAnimationFrame(() => drawAnimation(ctx, startedAt));
  };

  const initializeRenderingContext = (ctx: CanvasRenderingContext2D) => {
    ctx.globalCompositeOperation = 'source-over';
    ctx.clearRect(0, 0, size, size); // clear canvas
    ctx.lineWidth = lineWidth;
    ctx.lineCap = lineCap;
  };

  const drawBackgroundCircle = (ctx: CanvasRenderingContext2D) => {
    ctx.strokeStyle = config.theme.colors.secondary.light4;
    ctx.beginPath();
    ctx.arc(halfSize, halfSize, radius, 0, fullAngle, false);
    ctx.stroke();
  };

  const drawProgressCircle = (ctx: CanvasRenderingContext2D, angle: number) => {
    ctx.strokeStyle = config.theme.colors.brand.default;
    ctx.beginPath();
    ctx.arc(halfSize, halfSize, radius, 0, angle, false);
    ctx.stroke();
  };

  useEffect(() => {
    const ctx = ref.current?.getContext('2d');
    if (!ctx) return;
    initializeRenderingContext(ctx);
    window.requestAnimationFrame(() =>
      timerStartAt === null
        ? drawBackgroundCircle(ctx)
        : drawAnimation(ctx, timerStartAt)
    );
  }, [timerStartAt]);

  return <canvas ref={ref} width={size} height={size} />;
};

export default ProgressCircle;
