import { useTranslation } from 'react-i18next';
import { Title } from '../../../components/shared';
import { RegisterStepsProps } from '../../../shared/data/types/register';
import styles from './EmailVerification.module.css';

const EmailVerification = ({ dataTestid }: RegisterStepsProps) => {
  const { t } = useTranslation(['register_page']);

  return (
    <div className={styles.content} data-testId={dataTestid}>
      <Title customStyles={styles.title}>{t('check-your-email')}</Title>
      <p className={styles.text}>
        {t(
          'we-just-sent-you-an-email-please-check-your-inbox-to-verify-your-email-address'
        )}
      </p>
    </div>
  );
};

export default EmailVerification;
