import { SecurityBadge, Themes } from '../../../../shared/data/types';

import { ReactComponent as StarsSvg } from '../../../../assets/icons/security-badge/2fa/fa1.svg';
import { ReactComponent as StarsBSvg } from '../../../../assets/icons/security-badge/2fa/fa1-pwless.svg';
import { ReactComponent as Stars2Svg } from '../../../../assets/icons/security-badge/2fa/fa2.svg';
import { ReactComponent as Stars2BSvg } from '../../../../assets/icons/security-badge/2fa/fa2-pwless.svg';
import { ReactComponent as Stars3Svg } from '../../../../assets/icons/security-badge/2fa/fa3.svg';
import styles from '../../../../shared/styles/securityBadge.module.css';
import { useConfig } from '../../context/Config';

enum TotalStars {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

type Props = {
  color: SecurityBadge.Style;
  isPwless: boolean;
  stars?: TotalStars;
};

function starIcon(
  stars: Props['stars'],
  color: SecurityBadge.Style,
  isPwless: Props['isPwless'],
  theme: Themes.Theme
) {
  const fill =
    color === 'positive'
      ? theme.colors.secondary.positive
      : theme?.colors?.secondary?.light2;

  switch (stars) {
    case TotalStars.ZERO:
      return null;
    case TotalStars.ONE:
      return isPwless ? (
        <StarsBSvg className={styles.svg} fill={fill} />
      ) : (
        <StarsSvg className={styles.svg} fill={fill} />
      );
    case TotalStars.TWO:
      return isPwless ? (
        <Stars2BSvg className={styles.svg} fill={fill} />
      ) : (
        <Stars2Svg className={styles.svg} fill={fill} />
      );
    case TotalStars.THREE:
      return <Stars3Svg className={styles.svg} fill={fill} />;

    default:
      return null;
  }
}

const Stars = ({ color, isPwless, stars }: Props) => {
  const { config } = useConfig();

  return (
    <div className={styles.wrapper}>
      {starIcon(stars, color, isPwless, config.theme)}
    </div>
  );
};

Stars.defaultProps = {
  stars: 0,
};

Stars.defaultProps = {
  isPwless: false,
};

export { Stars };
