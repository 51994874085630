import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LeftArrowSvg } from '../../assets/icons/leftArrowWhite.svg';
import { BackToAppButtonProps } from '../../shared/data/types/components';
import stylesDesktop from './desktop/Sidebar.module.css';
import stylesMobile from './mobile/Sidebar.module.css';
import { useQuery } from '../../shared/utils/hooks';
import { useConfig } from '../shared/context/Config';
import { OAuth2Client } from '../../shared/data/types/oidc';
import { useOidcContext } from '../shared/context/OidcContext';
import { clientMatchesReturnTo } from '../../shared/utils/oidc';

const BackToAppButton = ({
  radius,
  foregroundColor,
  isDesktop = true,
}: BackToAppButtonProps) => {
  const { config } = useConfig();
  const { t } = useTranslation(['sidebar', 'register_page']);
  const query = useQuery();
  const oidcClients = useOidcContext();
  const [clientURI, setClientURI] = useState<string | null>(
    config.appSettings.redirectUrl
  );
  const [clientName, setClientName] = useState<string>('');
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
  const styles = isDesktop ? stylesDesktop : stylesMobile;

  useEffect(() => {
    const returnToFromQuery = query.get('return_to');

    if (returnToFromQuery?.startsWith('/')) {
      setClientURI(returnToFromQuery);
    }

    const allowedClients = oidcClients.filter((client: OAuth2Client) => {
      return clientMatchesReturnTo(returnToFromQuery, client);
    });

    if (allowedClients.length > 0) {
      setClientURI(returnToFromQuery);
      if (allowedClients[0]?.client_name) {
        setClientName(allowedClients[0].client_name);
      }
    }

    setIsPageLoaded(true);
  }, []);

  return isPageLoaded && clientURI ? (
    <div className={styles.backButtonWrapper}>
      <a
        href={clientURI}
        className={styles.backButton}
        style={{
          borderColor: foregroundColor,
          color: foregroundColor,
          borderRadius: radius
            ? `${radius}px`
            : config.theme?.components?.textbox?.radius,
        }}
        data-testid="sidebar-goBack"
      >
        <LeftArrowSvg
          fill={foregroundColor}
          className={styles.backIcon}
          role="presentation"
        />
        {clientName ? t('back-to-tenant', { tenant: clientName }) : t('back')}
      </a>
    </div>
  ) : null;
};

export default BackToAppButton;
