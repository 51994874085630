import { useConfig } from '../context/Config';
import { Helmet } from 'react-helmet';

const DocumentHead = () => {
  const { config } = useConfig();

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content={config.theme.brand.pageDescription} />
      <meta name="robots" content="noindex,nofollow" />
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href={config.theme.brand.faviconUrl}
      />
      <title>{config.theme.brand.pageTitle}</title>
    </Helmet>
  );
};

export default DocumentHead;
