import cx from 'classnames';
import { Components } from '../../../../shared/data/types';
import styles from './Multistep.module.css';

const Multistep = ({
  activeStep,
  totalSteps,
}: Components.Modal.MultistepProps) => {
  function renderDots() {
    let dotsArray = [];

    for (let index = 1; index <= totalSteps; index++) {
      dotsArray.push(
        <div
          key={index}
          className={cx(styles.dot, { [styles.active]: activeStep >= index })}
        />
      );
    }

    return dotsArray;
  }

  return <div className={styles.wrapper}>{renderDots()}</div>;
};

Multistep.defaultProps = {
  activeStep: 1,
};

export default Multistep;
