import styled from 'styled-components';
import CheckEmailIllustration from './check-email-illustration/CheckEmailIllustration';

const Wrapper = styled.div``;

const renderIllustration = (name: ThemedIllustrationProps['name']) => {
  switch (name) {
    case 'checkEmail':
      return <CheckEmailIllustration />;

    default:
      return null;
  }
};

type ThemedIllustrationProps = {
  name: 'checkEmail';
};

const ThemedIllustration = ({ name }: ThemedIllustrationProps) => {
  return <Wrapper>{renderIllustration(name)}</Wrapper>;
};

export default ThemedIllustration;
