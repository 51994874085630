import cx from 'classnames';
import { Button, SvgIcon } from '../';
import { IconsType } from '../../../shared/data/types';
import { useOutOfBoundsClick } from '../../../shared/utils/hooks';

import styles from './DropdownButton.module.css';
import { useConfig } from '../context/Config';

type Props = {
  children: React.ReactNodeArray;
  icon: IconsType['name'];
  customStyles?: string;
};

const DropdownButton = ({ children, icon, customStyles }: Props) => {
  const { config } = useConfig();
  const { ref, isVisible, setIsVisible } = useOutOfBoundsClick(false);

  return (
    <div className={cx(styles.wrapper, customStyles)} ref={ref}>
      <Button
        linkStyle
        onClick={() => setIsVisible(true)}
        customStyles={styles.button}
      >
        <SvgIcon
          name={icon}
          style={{ width: '100%', height: 'auto' }}
          fillColor={config.theme.colors.brand.text}
        />
      </Button>
      <ul className={cx(styles.dropdown, { [styles.visible]: isVisible })}>
        {children.map((option, index) => (
          <li key={index} onClick={() => setIsVisible(false)}>
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownButton;
