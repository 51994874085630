import React, { createContext, useContext, useEffect, useState } from 'react';
import { Theme } from '../../../shared/data/types/themes';
import { AppSettings } from '../../../shared/data/types';
import merge from 'ts-deepmerge';

import defaultTheme from '../../../shared/data/defaultTheme.json';
import defaultAppSettings from '../../../shared/data/defaultAppSettings.json';
import i18n from 'i18next';

type ConfigApiResponse = {
  theme: Theme;
  appSettings: AppSettings;
};

type Config = {
  isConfigLoaded: boolean;
  config: ConfigApiResponse;
};

type Props = {
  noFetch?: boolean;
  children: React.ReactNode;
};

const ConfigContext = createContext<Config>({} as Config);

const ConfigContextProvider = (props: Props) => {
  const [config, setConfig] = useState<ConfigApiResponse>(
    {} as ConfigApiResponse
  );
  const [isConfigLoaded, setIsConfigLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (props.noFetch) {
      setConfig({ theme: defaultTheme, appSettings: defaultAppSettings });
      setIsConfigLoaded(true);
      return;
    }
    fetch(`${process.env.REACT_APP_API_URL}/config`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((response: ConfigApiResponse) => {
        const opts = { mergeArrays: false };
        response.theme = merge.withOptions(opts, defaultTheme, response.theme);
        response.appSettings = merge.withOptions(
          opts,
          defaultAppSettings,
          response.appSettings
        );
        if (response.appSettings.locale) {
          const locale = response.appSettings.locale.replace('_', '-');
          i18n.changeLanguage(locale).finally(() => {
            setConfig(response);
            setIsConfigLoaded(true);
          });
        } else {
          setConfig(response);
          setIsConfigLoaded(true);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <ConfigContext.Provider value={{ config, isConfigLoaded }}>
      {props.children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
};

export default ConfigContextProvider;
