import cx from 'classnames';
import { SvgIcon } from '..';
import { Components } from '../../../shared/data/types';
import styles from './AlertBox.module.css';
import { useConfig } from '../context/Config';

const AlertBox = ({
  children,
  customStyles,
  type,
}: Components.AlertBoxProps) => {
  const { config } = useConfig();
  const renderIcon = (type: Components.AlertBoxProps['type']) => {
    switch (type) {
      case 'error':
        return (
          <SvgIcon
            name="exclamationFilled"
            fillColor={config.theme.colors.secondary.negative}
          />
        );
      case 'success':
        return (
          <SvgIcon
            name="check"
            fillColor={config.theme.colors.secondary.positive}
          />
        );

      default:
        return 'exclamationFilled';
    }
  };

  return (
    <div
      className={cx(styles.wrapper, customStyles)}
      style={{
        backgroundColor:
          type === 'success'
            ? `${config.theme.colors.secondary.positive}11`
            : `${config.theme.colors.secondary.negative}11`,
        color:
          type === 'success'
            ? config.theme.colors.secondary.positive
            : config.theme.colors.secondary.negative,
        borderRadius: config.theme.components.textbox.radius,
      }}
    >
      <span className={styles.icon}>{renderIcon(type)}</span>
      <span className={styles.text}>{children}</span>
    </div>
  );
};

export default AlertBox;
