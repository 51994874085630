import cx from 'classnames';
import { Button } from '../../../shared';
import { Themes } from '../../../../shared/data/types';

import styles from './Header.module.css';
import { useConfig } from '../../context/Config';

type HeaderProps = {
  button?: Cta;
  children: React.ReactNodeArray | React.ReactNode;
  customStyles?: string;
  noBorder?: boolean;
  sideNote?: string | React.ReactElement;
  small?: boolean;
};

type Cta = {
  customStyles?: string;
  dataTestid?: string;
  onClick: () => void;
  text: string;
  theme?: Themes.PrimaryButtonTheme;
  isPrimary?: boolean;
};

const Header = ({
  button,
  children,
  customStyles,
  noBorder,
  sideNote,
  small,
}: HeaderProps) => {
  const { config } = useConfig();
  return button ? (
    <div
      className={cx(
        styles.header,
        customStyles,
        { [styles.hasButton]: !!button },
        { [styles.smallTitle]: small },
        { [styles.noBorder]: noBorder }
      )}
      style={{ borderColor: config.theme.colors.secondary.light3 }}
    >
      <div>{children}</div>
      <Button
        primary={button.isPrimary}
        onClick={button.onClick}
        dataTestid={button.dataTestid}
        customStyles={cx(styles.headerCta, button.customStyles)}
      >
        {button.text}
      </Button>
    </div>
  ) : (
    <div
      className={cx(
        styles.header,
        customStyles,
        { [styles.smallTitle]: small },
        { [styles.hasSidenote]: !!sideNote }
      )}
      style={{ borderColor: config.theme.colors.secondary.light3 }}
    >
      {children}
      {sideNote ? (
        <span
          style={{ color: config.theme.colors.secondary.positive }}
          className={styles.sideNote}
        >
          {sideNote}
        </span>
      ) : null}
    </div>
  );
};

export default Header;
