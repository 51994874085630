import { useTranslation } from 'react-i18next';
import { Form, Modal, Textbox } from '../../../';
import { Components } from '../../../../../shared/data/types';
import styles from './EditRecord.module.css';
import { ChangeEvent, useState } from 'react';
import { useKratosFlow } from '../../../../../shared/utils/hooks';
import {
  BaseError,
  SessionExpiredError,
} from '../../../../../shared/data/errors/error';
import { useHistory } from 'react-router';
import { useConfig } from '../../../context/Config';
const EditRecord = ({
  onCancel,
  credential,
  flow,
  setFlow,
}: Components.Modal.EditRecordProps) => {
  const { config } = useConfig();
  const { t } = useTranslation(['modal_edit_record', 'common']);
  const history = useHistory();
  const {
    webauthnCredentialSettingsFlow,
    initializeSettingsFlow,
    normalizeKratosFlow,
  } = useKratosFlow();
  const [credentialName, setCredentialName] = useState<string>(credential.name);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCredentialName(event.target.value);
  };

  const handleCredentialUpdate = () => {
    webauthnCredentialSettingsFlow({
      settingsFlow: flow,
      credential_id: credential.id,
      credential_name: credentialName,
      credential_action: 'update',
    })
      .then(() => {
        initializeSettingsFlow().then((flow) =>
          setFlow(normalizeKratosFlow(flow))
        );
        onCancel();
      })
      .catch((error: BaseError) => {
        if (error instanceof SessionExpiredError) {
          history.push('/login');
        }
        console.error(error);
      });
  };

  return (
    <div>
      <p
        className={styles.title}
        style={{ color: config.theme.colors.brand.text }}
      >
        {t('modal_edit_record:edit-name')}
      </p>
      <Form onSubmit={handleCredentialUpdate}>
        <p
          className={styles.description}
          style={{ color: config.theme.colors.brand.text }}
        >
          {t('you-can-change-the-name-of-your-authenticator-device-here')}
        </p>
        <Textbox
          defaultValue={credential.name}
          onChange={handleInputChange}
          name="credential name"
          customStyles={styles.textbox}
          hideClearButton
          hasFocus
          required
        />
        <Modal.ButtonWrapper
          onClick={() => {}}
          onCancel={onCancel}
          label={t('common:done')}
          dataTestid={`edit-record-${credential.id}`}
        />
      </Form>
    </div>
  );
};

export default EditRecord;
