import { useTranslation } from 'react-i18next';
import { Components } from '../../../../../shared/data/types';
import { Button } from '../../../';

import styles from './ButtonWrapper.module.css';

const ButtonWrapper = ({
  cancelLabel,
  dataTestid,
  isDisabled,
  isLoading,
  onCancel,
  onClick,
  label,
}: Components.Modal.ButtonWrapperProps) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.wrapper}>
      {onCancel ? (
        <Button onClick={onCancel} linkStyle customStyles={styles.backButton}>
          {cancelLabel || t('cancel')}
        </Button>
      ) : null}
      <Button
        primary
        isDisabled={isDisabled}
        onClick={onClick}
        customStyles={styles.submitButton}
        type="submit"
        loading={isLoading}
        dataTestid={dataTestid}
      >
        {label}
      </Button>
    </div>
  );
};

export default ButtonWrapper;
