import { useTranslation } from 'react-i18next';
import { BaseError } from '../../../shared/data/errors/error';

type Props = {
  error: BaseError;
};

const TranslateError = ({ error }: Props) => {
  const { t } = useTranslation(['error']);
  return (
    <>
      {t(
        error.code !== '' ? `error:${error.code}` : 'error:something-went-wrong'
      )}
    </>
  );
};

export default TranslateError;
