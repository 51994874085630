import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Textbox } from '../../../..';
import { Components } from '../../../../../../shared/data/types';
import Modal from '../../../Modal';
import styles from '../AuthenticatorApp.module.css';

const Step3 = ({ saveAuthName }: Components.Modal.AuthenticatorAppStep3) => {
  const { t } = useTranslation(['modal_authenticator_app', 'common']);
  const defaultName = 'recordId';
  const [authName, setAuthName] = useState<string>(defaultName);

  return (
    <>
      <p className={styles.title}>Success!</p>
      <form>
        <p className={styles.desciption}>
          {t('modal_authenticator_app:choose-a-name-for-your-authenticator')}
        </p>
        <Textbox
          defaultValue={defaultName}
          name="authenticator name"
          customStyles={styles.textbox}
          hasFocus
          hideClearButton
          onChange={(e) => setAuthName(e.target.value)}
        />
        <Modal.ButtonWrapper
          onClick={() => saveAuthName(authName)}
          label={t('common:done')}
          dataTestid="modal-authenticator-app-done"
        />
      </form>
    </>
  );
};

export default Step3;
