import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Hr, Modal, SvgIcon, Title } from '../../../components/shared';
import { AlertBox } from '../../../components/shared';
import { Components } from '../../../shared/data/types';
import { RegisterStepsProps } from '../../../shared/data/types/register';
import { useModal } from '../../../shared/utils/hooks';
import BackButton from '../../../components/shared/back-button/BackButton';
import Select from '../select/Select';
import styles from './AuthApp.module.css';
import { useConfig } from '../../../components/shared/context/Config';

const AuthApp = ({ userEmail, dataTestid }: RegisterStepsProps) => {
  const { t } = useTranslation(['register_page', 'sign_in_page', 'common']);
  const { config } = useConfig();
  const { isModalVisible, modalContent, setModalContent, toggleModal } =
    useModal();
  const [hasError, setHasError] = useState<boolean>(false);

  function handleModalOpen({
    ...args
  }: Components.Modal.ModalProps['content']) {
    setModalContent({ ...args });
    toggleModal();
    setHasError(false);
  }

  return (
    <div className={styles.content} data-testid={dataTestid}>
      <BackButton
        text={userEmail}
        to="/register"
        dataTestid="register-back-button"
      />
      <Title customStyles={styles.title}>
        {t('register_page:secure-account')}
      </Title>
      <div className={styles.iconTextWrapper}>
        <SvgIcon
          name="smartphone"
          fillColor={config.theme.colors.secondary.default}
        />
        <span className={styles.text}>
          {t('register_page:autheticator-app')}
        </span>
      </div>
      {hasError ? (
        <AlertBox type="error" customStyles={styles.alert}>
          {t('common:something-went-wrong')}
        </AlertBox>
      ) : null}
      <p className={styles.authText}>
        {t(
          'register_page:use-your-phone-to-securely-authenticate-without-a-password'
        )}
      </p>
      <Button
        onClick={() => handleModalOpen({ id: 'hanko-authenticator-app' })}
        primary
        dataTestid="auth-app-submit"
        customStyles={styles.submit}
      >
        {t('register_page:set-up-authenticator-app')}
      </Button>
      <Hr text="or" customStyles={styles.divider} />
      <Select items={['platform-auth', 'key', 'password']} page="register" />
      <Modal
        isVisible={isModalVisible}
        content={modalContent}
        closeModal={toggleModal}
        customStyles={styles.modal}
      />
    </div>
  );
};

export default AuthApp;
