import styles from './ErrorPage.module.css';
import { Button } from '../../components/shared';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../components/shared/context/Config';
import BackButtonPlaceholder from '../../components/shared/back-button/BackButtonPlaceholder';
import TitleWithIcon from '../../components/shared/title/TitleWithIcon';
import { useState } from 'react';
import { useHistory } from 'react-router';

const ErrorPage = () => {
  const { config } = useConfig();
  const { t } = useTranslation(['common', 'error_page']);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const redirectToLogin = () => {
    setIsLoading(true);
    history.push('/login');
  };
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.content}
        style={{ color: config.theme.colors.brand.text }}
      >
        <BackButtonPlaceholder />
        <TitleWithIcon
          customStyles={styles.title}
          iconName={'sentimentDissatisfied'}
        >
          {t('error_page:you-ve-found-an-issue')}
        </TitleWithIcon>
        <p>{t('error_page:there-seems-to-be-a-misconfiguration')}</p>
        <Button
          onClick={redirectToLogin}
          type="button"
          primary
          loading={isLoading}
          customStyles={styles.button}
        >
          {t('common:back-to-login')}
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
