import cx from 'classnames';
import { Components } from '../../../shared/data/types';
import styles from './LoadingWheel.module.css';
import { useConfig } from '../context/Config';

const LoadingWheel = ({ size, customStyles }: Components.LoadingWheel) => {
  const { config } = useConfig();

  return (
    <div className={cx(styles.wrapper, styles[size], customStyles)}>
      <div
        className={`${styles.dot} ${styles[size]}`}
        style={{ backgroundColor: config.theme?.colors?.brand?.default }}
      />
    </div>
  );
};

LoadingWheel.defaultProps = {
  size: 'small',
};

export default LoadingWheel;
