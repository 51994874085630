import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router';
import { Link, LoadingWheel } from '../../components/shared';
import { RouteContext } from '../../components/shared/context';
import NewAccount from './new-account/NewAccount';
import EmailVerification from './email-verification/EmailVerification';
import AuthApp from './auth-app/AuthApp';
import Password from './password/Password';
import SecurityKey from './security-key/SecurityKey';
import Platform from './platform/Platform';

import styles from './RegisterPage.module.css';
import { useConfig } from '../../components/shared/context/Config';
import { useAfterLoginRedirect } from '../../shared/utils/hooks/useAfterLoginRedirect';

const RegisterPage = () => {
  const { t } = useTranslation(['sign_in_page', 'register_page']);
  const { config } = useConfig();
  const [userEmail, setUserEmail] = useState('');
  const { setIsRouteRegister } = useContext(RouteContext);
  const { appendQueryParamsTo } = useAfterLoginRedirect();

  useEffect(() => {
    const { signUpCustomUrl } = config.appSettings.signUp;

    if (signUpCustomUrl && signUpCustomUrl !== '') {
      window.location.href = signUpCustomUrl;
    }
  });

  useEffect(() => {
    const sessionEmail = sessionStorage.getItem('email');

    if (userEmail === '' && sessionEmail && sessionEmail.length > 0) {
      setUserEmail(sessionEmail);
    }
  }, [userEmail]);

  return config.appSettings.signUp.signUpCustomUrl ? (
    <LoadingWheel />
  ) : (
    <div className={styles.wrapper}>
      <div
        className={styles.loginCta}
        style={{ color: config.theme.colors.brand.text }}
      >
        {t('register_page:already-have-an-account')}
        <span className={styles.ctaLink}>
          <Link
            to={appendQueryParamsTo('/login')}
            onClick={() => setIsRouteRegister(false)}
          >
            {t('sign_in_page:sign-in')}
          </Link>
        </span>
      </div>
      <div className={styles.content}>
        <Switch>
          <Route path="/register" exact>
            <NewAccount
              updateUserEmail={(email: string) => setUserEmail(email)}
            />
          </Route>
          <Route path="/register/email-verification">
            <EmailVerification userEmail={userEmail} />
          </Route>
          <Route path="/register/password">
            <Password userEmail={userEmail} />
          </Route>
          <Route path="/register/windows-hello">
            <Platform userEmail={userEmail} dataTestid="register-hello-step" />
          </Route>
          <Route path="/register/security-key">
            <SecurityKey userEmail={userEmail} dataTestid="register-key-step" />
          </Route>
          <Route path="/register/hanko-app">
            <AuthApp
              userEmail={userEmail}
              dataTestid="register-auth-app-step"
            />
          </Route>
          <Redirect to="/register" />
        </Switch>
      </div>
    </div>
  );
};

export default RegisterPage;
