import { Trans, useTranslation } from 'react-i18next';
import { useKratosFlow } from '../../../shared/utils/hooks';
import styled from 'styled-components';
import { BaseError } from '../../../shared/data/errors/error';
import { ThemedIllustration, Title } from '../../../components/shared';
import styles from './Passlink.module.css';
import { NormalizedLoginFlow } from '../../../shared/data/types/kratosFlows';
import BackButton from '../../../components/shared/back-button/BackButton';
import { useLocation } from 'react-router';
import { useConfig } from '../../../components/shared/context/Config';
import { useAfterLoginRedirect } from '../../../shared/utils/hooks/useAfterLoginRedirect';
import RateLimitIndicator from '../../../components/shared/rate-limit-indicator/RateLimitIndicator';

const IllustrationWrapper = styled.div`
  display: block;
  margin-bottom: 30px;
`;

const Text = styled.p`
  margin-bottom: 25px;
  line-height: 2.27rem;
`;

const StrongText = styled.strong`
  font-weight: 500;
`;

type Props = {
  loginFlow: NormalizedLoginFlow;
  userEmail: string;
};

const Passlink = ({ loginFlow, userEmail }: Props) => {
  const { config } = useConfig();
  const { t } = useTranslation(['sign_in_page', 'common']);
  const { passlinkLoginFlow } = useKratosFlow();
  const { appendQueryParamsTo } = useAfterLoginRedirect();
  const location = useLocation();

  const sendPasslink = (): Promise<void> => {
    const templateState = location.state as { template?: string };
    return passlinkLoginFlow({
      loginFlow,
      userEmail,
      template: templateState?.template || 'login',
      template_locale: config.appSettings.locale,
    }).catch((error: BaseError) => {
      console.error(error.message);
    });
  };

  return (
    <>
      <BackButton text={userEmail} to={appendQueryParamsTo('/login')} />
      <Title customStyles={styles.title}>{t('check-your-email')}</Title>
      <IllustrationWrapper>
        <ThemedIllustration name="checkEmail" />
      </IllustrationWrapper>
      <Text style={{ color: config.theme.colors.brand.text }}>
        <Trans
          ns="sign_in_page"
          key="click-the-passlink-that-was-sent-via-email-to-less-than-b-greater-than-useremail-less-than-b-greater-than-to-login"
        >
          Click the Passlink that was sent via email to{' '}
          <StrongText>{{ userEmail }}</StrongText> to login.
        </Trans>
      </Text>
      <div className={styles.linkWrapper}>
        <RateLimitIndicator sendPasslinkCallback={sendPasslink} />
      </div>
    </>
  );
};

export default Passlink;
