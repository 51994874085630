class BaseError extends Error {
  code: string;
  cause?: Error;
  constructor(message: string, code: string, cause?: Error) {
    super(message);
    this.code = code;
    this.cause = cause;
    Object.setPrototypeOf(this, BaseError.prototype);
  }
}

class GeneralError extends BaseError {
  constructor(cause?: Error) {
    super('General error', 'something-went-wrong', cause);
    Object.setPrototypeOf(this, GeneralError.prototype);
  }
}

class RequestCancelledError extends BaseError {
  constructor(cause?: Error) {
    super('Request cancelled error', 'request-cancelled-error', cause);
    Object.setPrototypeOf(this, GeneralError.prototype);
  }
}

class LinkExpiredError extends BaseError {
  constructor(cause?: Error) {
    super('The link has expired', 'link-expired-error', cause);
    Object.setPrototypeOf(this, LinkExpiredError.prototype);
  }
}

class InvalidCredentialsError extends BaseError {
  constructor(cause?: Error) {
    super('Invalid credentials', 'invalid-credentials-error', cause);
    Object.setPrototypeOf(this, InvalidCredentialsError.prototype);
  }
}

class PasswordTooSimilarToIdentifierError extends BaseError {
  constructor(cause?: Error) {
    super(
      'Password is to similar to your email',
      'password-to-similiar-to-identifier',
      cause
    );
    Object.setPrototypeOf(this, PasswordTooSimilarToIdentifierError.prototype);
  }
}

class DuplicateCredentialsError extends BaseError {
  constructor(cause?: Error) {
    super('Duplicate credentials', 'duplicate-credentials-error', cause);
    Object.setPrototypeOf(this, DuplicateCredentialsError.prototype);
  }
}

class TooManyRequestsError extends BaseError {
  constructor(cause?: Error) {
    super('Too many requests', 'too-many-requests-error', cause);
    Object.setPrototypeOf(this, TooManyRequestsError.prototype);
  }
}

class SessionExpiredError extends BaseError {
  constructor(cause?: Error) {
    super('Session has expired', 'session-expired-error', cause);
    Object.setPrototypeOf(this, SessionExpiredError.prototype);
  }
}

export {
  GeneralError,
  RequestCancelledError,
  LinkExpiredError,
  InvalidCredentialsError,
  DuplicateCredentialsError,
  TooManyRequestsError,
  SessionExpiredError,
  BaseError,
  PasswordTooSimilarToIdentifierError,
};
