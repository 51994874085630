import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import cx from 'classnames';
import sha1 from 'sha1';
import { Button, Textbox } from '..';
import { Components } from '../../../shared/data/types';
import PasswordLevel from '../../../shared/data/enums/components/password-box';
import styles from './PasswordBox.module.css';
import SvgIcon from '../svg-icon/SvgIcon';
import { useConfig } from '../context/Config';

type CheckWithHIBPArgs = {
  password: string;
  score: 0 | 1 | 2 | 3 | 4;
};

const PasswordBox = ({
  customStyles,
  hasFocus,
  name,
  onChange,
  passwordStength,
  placeholder,
  validatePattern,
  scoreUpdated,
  showPassword,
}: Components.PasswordBox.PasswordBoxProps) => {
  const { t } = useTranslation('common');
  const { config } = useConfig();
  const [newPassword, setNewPassword] = useState<string>('');
  const [securityLevel, setSecurityLevel] = useState<PasswordLevel>(
    PasswordLevel.SHORT
  );
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const MIN_PASSWORD_LENGTH = 8;
  const PASSWORD_PATTERN = `.{${MIN_PASSWORD_LENGTH},}`;

  async function checkPasswordStrength(password: string) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/password/score`,
        {
          method: 'POST',
          body: JSON.stringify({ password }),
        }
      );
      const { score } = await response.json();

      if (score > 2) {
        return checkWithHIBP({ password, score });
      }

      scoreUpdated({ securityLevel: score });
      return setSecurityLevel(score);
    } catch (error) {
      console.error('Error:', error);

      scoreUpdated({ securityLevel: PasswordLevel.ERROR });
      return setSecurityLevel(PasswordLevel.ERROR);
    }
  }

  async function checkWithHIBP({ password, score }: CheckWithHIBPArgs) {
    const hash = sha1(password).toUpperCase();
    const HASH_PREFIX_LENGTH = 5;

    const prefix = hash.substr(0, HASH_PREFIX_LENGTH);
    const suffix = hash.substr(HASH_PREFIX_LENGTH);

    try {
      const response = await fetch(process.env.REACT_APP_HIBP_URL + prefix, {
        mode: 'cors',
        referrerPolicy: 'no-referrer',
        cache: 'no-cache',
      });

      let result = null;
      if (response.ok) {
        result = await response.text();
      } else {
        scoreUpdated({ securityLevel: PasswordLevel.ERROR });
        return setSecurityLevel(PasswordLevel.ERROR);
      }

      if (result) {
        const suffixes = result.split('\n').map((entry) => {
          const [suffix, count] = entry.split(':');
          return { suffix, count: parseInt(count, 10) };
        });

        const found = suffixes.find((entry) => entry.suffix === suffix);
        scoreUpdated({
          securityLevel: found ? PasswordLevel.COMPROMISED : score,
          totalBreaches: found?.count,
        });
        setSecurityLevel(found ? PasswordLevel.COMPROMISED : score);
      }
    } catch (error) {
      console.error(error);

      scoreUpdated({ securityLevel: PasswordLevel.ERROR });
      return setSecurityLevel(PasswordLevel.ERROR);
    }
  }

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const {
      target: { value },
    } = e;

    setNewPassword(value);
    onChange && onChange(e);

    if (passwordStength) {
      if (value.length < MIN_PASSWORD_LENGTH) {
        scoreUpdated({ securityLevel: PasswordLevel.SHORT });
        return setSecurityLevel(PasswordLevel.SHORT);
      } else {
        checkPasswordStrength(value);
      }
    }
  }

  function strengthLabel() {
    switch (securityLevel) {
      case PasswordLevel.SHORT:
        return t('too-short');

      case PasswordLevel.ERROR:
        return t('could-not-validate-password');

      case PasswordLevel.COMPROMISED:
        return t('password-compromised');

      case PasswordLevel.ZERO:
      case PasswordLevel.ONE:
        return t('weak');

      case PasswordLevel.TWO:
        return t('okay');

      case PasswordLevel.THREE:
        return t('good');

      case PasswordLevel.FOUR:
        return t('strong');

      default:
        return t('too-short');
    }
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.textboxWrapper}
        style={{ borderRadius: config.theme?.components?.textbox?.radius }}
      >
        <Textbox
          name={name}
          onChange={handleOnChange}
          type={isPasswordVisible ? 'text' : 'password'}
          customStyles={cx(styles.input, customStyles)}
          placeholder={placeholder}
          defaultValue={isPasswordVisible ? newPassword : ''}
          hasFocus={hasFocus}
          hasButton
          hideClearButton
          pattern={validatePattern ? PASSWORD_PATTERN : undefined}
          required
          maxLength={64}
        />
        {passwordStength ? (
          <div
            className={cx(
              styles.passwordStrengthIndicator,
              {
                [styles.off]:
                  newPassword.length <= 0 ||
                  securityLevel === PasswordLevel.ERROR ||
                  securityLevel === PasswordLevel.COMPROMISED,
              },
              { [styles.weak]: securityLevel === PasswordLevel.ONE },
              { [styles.green]: securityLevel >= PasswordLevel.TWO },
              { [styles.okay]: securityLevel === PasswordLevel.TWO },
              { [styles.good]: securityLevel === PasswordLevel.THREE },
              { [styles.strong]: securityLevel === PasswordLevel.FOUR }
            )}
          />
        ) : null}
        {showPassword ? (
          <Button
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            linkStyle
            customStyles={styles.showPassword}
            dataTestid="passwordBox-show-password"
          >
            <SvgIcon
              name={isPasswordVisible ? 'eyeClosed' : 'eyeOpen'}
              fillColor={config.theme.colors.brand.text}
            />
          </Button>
        ) : null}
      </div>
      {passwordStength && newPassword.length > 0 ? (
        <div className={styles.buttonWrapper}>
          <small
            className={cx(styles.passwordStrengthLabel, {
              [styles.green]: securityLevel >= PasswordLevel.TWO,
            })}
            style={{
              color:
                securityLevel >= PasswordLevel.TWO
                  ? config.theme.colors.secondary.positive
                  : config.theme.colors.secondary.negative,
            }}
          >
            {strengthLabel()}
          </small>
        </div>
      ) : null}
    </div>
  );
};

PasswordBox.defaultProps = {
  passwordStength: 0,
};

export default PasswordBox;
