import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  Button,
  Form,
  Hr,
  PasswordBox,
  SvgIcon,
  Title,
} from '../../../components/shared';
import { AlertBox } from '../../../components/shared';
import { RegisterStepsProps } from '../../../shared/data/types/register';
import { usePasswordSecurityText } from '../../../shared/utils/hooks';
import BackButton from '../../../components/shared/back-button/BackButton';
import Select from '../select/Select';
import styles from './Password.module.css';
import { useConfig } from '../../../components/shared/context/Config';

const Password = ({ userEmail }: RegisterStepsProps) => {
  const { t } = useTranslation(['register_page', 'common']);
  const history = useHistory();
  const { config } = useConfig();
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [currentSecurityLevel, setCurrentSecurityLevel] =
    useState<any>(undefined);
  const [currentBreaches, setCurrentBreaches] = useState<any>(undefined);
  const { smallTip } = usePasswordSecurityText({
    securityLevel: currentSecurityLevel,
    totalBreaches: currentBreaches,
  });
  const MIN_PASSWORD_LENGTH = 8;
  const MIN_SECURITY_LEVEL = 2;
  const isPasswordValid =
    currentPassword.length >= MIN_PASSWORD_LENGTH &&
    currentSecurityLevel >= MIN_SECURITY_LEVEL;

  function handleScoreUpdate({
    securityLevel,
    totalBreaches,
  }: {
    securityLevel: any;
    totalBreaches: any;
  }) {
    setCurrentSecurityLevel(securityLevel);
    setCurrentBreaches(totalBreaches);
  }

  async function handleSubmit() {
    setIsLoading(false);

    if (!isPasswordValid) {
      return false;
    }

    setHasError(false);

    try {
      setIsLoading(true);
      const submitPassword = await fetch(
        `${process.env.REACT_APP_USERS}/uuid/password`,
        {
          method: 'PUT',
          credentials: 'include',
          body: JSON.stringify({
            password: currentPassword,
          }),
        }
      );

      const submitLogin = await fetch(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SIGN_IN_PASSWORD}`,
        {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            email: userEmail,
            password: currentPassword,
          }),
        }
      );

      await submitPassword.json();

      const loginResponse = await submitLogin.json();

      return history.push(loginResponse?.redirect?.url);
    } catch (error) {
      setHasError(true);
      console.error(error);
      setIsLoading(false);
    }
  }

  return (
    <div className={styles.content}>
      <BackButton
        text={userEmail}
        to="/register"
        dataTestid="register-back-button"
      />
      <Title customStyles={styles.title}>
        {t('register_page:set-your-password')}
      </Title>
      <div className={styles.inlineIconText}>
        <span className={styles.iconWrapper}>
          <SvgIcon name="password" />
        </span>
        <span>
          {t(
            'register_page:a-strong-and-unique-password-can-still-provide-a-decent-level-of-security'
          )}
        </span>
      </div>
      {hasError ? (
        <AlertBox type="error" customStyles={styles.alert}>
          {t('common:something-went-wrong')}
        </AlertBox>
      ) : null}
      <Form onSubmit={handleSubmit}>
        <PasswordBox
          name="register-new-password"
          onChange={(e) => setCurrentPassword(e.target.value)}
          scoreUpdated={({ securityLevel, totalBreaches }) =>
            handleScoreUpdate({ securityLevel, totalBreaches })
          }
          hasFocus
          passwordStength
          showPassword
          placeholder={t('common:new-password')}
          customStyles={styles.passwordBox}
        />
        <p className={styles.smallTip}>{smallTip}</p>
        <Button
          onClick={() => {}}
          primary
          type="submit"
          isDisabled={!isPasswordValid}
          loading={isLoading}
          dataTestid="register-password-submit"
          customStyles={styles.submit}
        >
          {t('common:submit')}
        </Button>
      </Form>
      {config.appSettings?.login?.loginOptions ? (
        <>
          <Hr text={t('common:or')} customStyles={styles.divider} />
          <Select
            items={config.appSettings?.login?.loginOptions}
            page="register"
          />
        </>
      ) : null}
    </div>
  );
};

export default Password;
