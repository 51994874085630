import { useKratosFlow } from '../../../shared/utils/hooks';
import styles from './Error.module.css';

import Select from '../select/Select';
import { NormalizedLoginFlow } from '../../../shared/data/types/kratosFlows';
import { AlertBox, Button, Title } from '../../../components/shared';
import { useContext } from 'react';
import { OverlayContext } from '../../../components/shared/context';
import { useTranslation } from 'react-i18next';
import BackButton from '../../../components/shared/back-button/BackButton';
import TranslateError from '../../../components/shared/error/TranslateError';
import { RequestCancelledError } from '../../../shared/data/errors/error';
import { getPlatformAuthenticatorAlias } from '@teamhanko/hanko-webauthn';
import { useAfterLoginRedirect } from '../../../shared/utils/hooks/useAfterLoginRedirect';

type Props = {
  loginFlow: NormalizedLoginFlow;
  successRedirect: (showIntercept: boolean) => void;
  getUserEmail: () => string;
};

const Error = ({ loginFlow, successRedirect, getUserEmail }: Props) => {
  const { setIsOverlayVisible } = useContext(OverlayContext);
  const { webauthnLoginFlow } = useKratosFlow();
  const { appendQueryParamsTo } = useAfterLoginRedirect();
  const { t } = useTranslation(['sign_in_page', 'common']);

  const handleOnClick = () => {
    setIsOverlayVisible(true);
    webauthnLoginFlow({
      attachment: 'platform',
      loginFlow,
      userEmail: getUserEmail(),
    })
      .then(() => {
        successRedirect(false);
      })
      .catch((error) => {
        console.error(error.message);
      })
      .finally(() => setIsOverlayVisible(false));
  };

  return (
    <>
      <BackButton text={getUserEmail()} to={appendQueryParamsTo('/login')} />
      <Title customStyles={styles.title}>
        {t('sign_in_page:sign-in-with', {
          userBiometricsLabel: getPlatformAuthenticatorAlias(
            navigator.userAgent
          ),
        })}
      </Title>
      <AlertBox type={'error'} customStyles={styles.alert}>
        <TranslateError error={new RequestCancelledError()} />
      </AlertBox>
      <Button onClick={handleOnClick} primary customStyles={styles.fullWidth}>
        {t('common:retry')}
      </Button>
      <Select
        excludeLoginOption={'platform-auth'}
        loginFlow={loginFlow}
        getUserEmail={getUserEmail}
        successRedirect={successRedirect}
      />
    </>
  );
};

export default Error;
