import { useContext, useEffect, useState } from 'react';
import { SettingsFlow } from '@ory/kratos-client';
import { Trans, useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import cx from 'classnames';
import {
  Button,
  DropdownButton,
  Link,
  MainWrapper,
  Modal,
  Section,
  TableList,
  Title,
} from '../../components/shared';
import { useKratosFlow, useModal } from '../../shared/utils/hooks';
import {
  OverlayContext,
  RouteContext,
  UserContext,
} from '../../components/shared/context';
import { Components } from '../../shared/data/types';
import { Credential } from '../../shared/data/types/apiData';
import { MEDIA_QUERIES } from '../../shared/utils/mediaQueries';
import styles from './ProfilePage.module.css';

import { useHistory } from 'react-router';
import { BaseError, SessionExpiredError } from '../../shared/data/errors/error';
import { useConfig } from '../../components/shared/context/Config';
import { useAfterLoginRedirect } from '../../shared/utils/hooks/useAfterLoginRedirect';

const ProfilePage = () => {
  const { t } = useTranslation(['profile_page', 'common']);
  const { config } = useConfig();
  const { userEmail } = useContext(UserContext);
  const { setShouldShowSidebarBackButton, setIsRouteRegister } =
    useContext(RouteContext);
  const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });
  const [isPageDataLoaded, setIsPageDataLoaded] = useState<boolean>(false);
  const history = useHistory();
  const { appendQueryParamsTo, makeLoginReturnToPath } =
    useAfterLoginRedirect();

  const { isModalVisible, modalContent, setModalContent, toggleModal } =
    useModal();
  const {
    newFlow,
    setNewFlow,
    webauthnSettingsFlow,
    normalizeKratosFlow,
    initializeSettingsFlow,
  } = useKratosFlow();
  const [credentials, setCredentials] = useState<Credential[]>(
    [] as Credential[]
  );
  const { setIsOverlayVisible } = useContext(OverlayContext);

  function handleOnClick(attachment: 'platform' | 'cross-platform') {
    setIsOverlayVisible(true);

    webauthnSettingsFlow({ attachment, settingsFlow: newFlow })
      .then(() => {
        localStorage.setItem('2FaReminder', 'off');
      })
      .catch((error: BaseError) => {
        if (error instanceof SessionExpiredError) {
          history.push(makeLoginReturnToPath('/profile'));
        }
      })
      .finally(() => setIsOverlayVisible(false));
  }

  useEffect(() => {
    setIsRouteRegister(false);
    initializeSettingsFlow()
      .then((flow: SettingsFlow) => {
        setIsPageDataLoaded(true);
        setNewFlow(normalizeKratosFlow(flow));
      })
      .catch((error: BaseError) => {
        if (error instanceof SessionExpiredError) {
          history.push(makeLoginReturnToPath('/profile'));
        }
      });
  }, []);

  useEffect(() => {
    try {
      if (Object.keys(newFlow).length > 0) {
        const credentials = JSON.parse(newFlow.group?.hanko?.credentials);
        setCredentials(credentials);
      }
    } catch (error) {
      console.error(error);
    }
  }, [newFlow]);

  useEffect(() => {
    setShouldShowSidebarBackButton(true);
  }, []);

  function handleModalOpen({
    ...args
  }: Components.Modal.ModalProps['content']) {
    setModalContent({ ...args });
    toggleModal();
  }

  return isPageDataLoaded ? (
    <>
      <MainWrapper>
        <div className={styles.header}>
          <div>
            <Title customStyles={styles.pageTitle}>
              {t('account_security')}
            </Title>
            {/* <Link to="/history" customStyles={styles.historyLinkWrapper}>
              <SvgIcon name="history" fillColor={textLight2Color} />
              <span
                className={styles.historyLinkText}
                style={{
                  color: textLight2Color,
                  textDecoration: `underline ${textLight2Color}`,
                }}
              >
                {t('sign-in-history')}
              </span>
            </Link> */}
          </div>
          {/* <div className={styles.securityBoard}>
            <div className={styles.badgeWrapper}>
              <Link to="/security" customStyles={styles.badgeLink}>
                {t('security-level')}
              </Link>
              <SecurityBadge
                security={userProfile.security}
                customStyles={styles.badge}
              />
            </div>
          </div> */}
        </div>
        <Section>
          <Section.Header small customStyles={styles.mobileSectionHeader}>
            <Title type="h2">{t('my-account')}</Title>
          </Section.Header>
          <div className={styles.contentCtaWrapper}>
            <div className={styles.leftColumn}>
              <p className={styles.contentHeader}>{userEmail}</p>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.accountButtonWrapper}>
                <div className={styles.accountButton}>
                  <Link
                    to={appendQueryParamsTo('/account')}
                    buttonStyle
                    customWrapperStyles={styles.accountButton}
                    customStyles={cx(styles.buttonStyle, styles.accountLink, {
                      [styles.inlineButton]: !isSmallScreen,
                    })}
                  >
                    {t('edit-account-0')}
                  </Link>
                </div>
                {config.appSettings.login.loginOptions.includes('password') ? (
                  <Button
                    onClick={() =>
                      handleModalOpen({ id: 'edit-password', flow: newFlow })
                    }
                    customStyles={cx(styles.accountButton, {
                      [styles.inlineButton]: !isSmallScreen,
                    })}
                  >
                    {t('change-password')}
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </Section>
        {config.appSettings.login.loginOptions.includes('platform-auth') ? (
          <Section>
            <Section.Header
              button={
                isSmallScreen
                  ? undefined
                  : {
                      onClick: () => handleOnClick('platform'),
                      customStyles: styles.inlineButton,
                      isPrimary: true,
                      text: t('add-this-device'),
                    }
              }
            >
              <Section.TitleWrapper>
                <Section.Icon
                  name="fingerprint"
                  fillColor={config.theme.colors.brand.default}
                />
                <Title type="h2">{t('device-biometrics')}</Title>
              </Section.TitleWrapper>
              <Section.Subtitle>
                <Trans
                  ns={'profile_page'}
                  i18nKey="set-up-this-device-to-authenticate-using-touch-id-or-windows-hello-and-similar-methods"
                >
                  Set up this device to authenticate using <b>Touch ID</b> or{' '}
                  <b>Windows Hello</b>.
                </Trans>
              </Section.Subtitle>
            </Section.Header>
            <TableList
              data={credentials?.filter(
                (credential) =>
                  credential.authenticator?.attachment === 'platform'
              )}
              rowButton={(credential) => (
                <DropdownButton
                  icon="dots"
                  customStyles={styles.optionDropdown}
                >
                  <Button
                    onClick={() =>
                      handleModalOpen({
                        id: 'edit-record',
                        data: credential,
                        flow: newFlow,
                        setFlow: setNewFlow,
                      })
                    }
                    dropdown
                  >
                    {t('edit-name')}
                  </Button>
                  <Button
                    onClick={() =>
                      handleModalOpen({
                        id: 'delete-record',
                        data: credential,
                        flow: newFlow,
                        setFlow: setNewFlow,
                      })
                    }
                    dropdown
                  >
                    {t('common:delete')}
                  </Button>
                </DropdownButton>
              )}
            />
            {isSmallScreen ? (
              <Button
                primary
                onClick={() => handleOnClick('platform')}
                customStyles={styles.fluidButton}
              >
                {t('add-this-device')}
              </Button>
            ) : null}
          </Section>
        ) : null}
        {config.appSettings.login.loginOptions.includes('key') ? (
          <Section>
            <Section.Header
              button={{
                onClick: () => handleOnClick('cross-platform'),
                text: t('add-new'),
                customStyles: styles.inlineButton,
                isPrimary: true,
              }}
            >
              <Section.TitleWrapper>
                <Section.Icon name="key" />
                <Title type="h2">{t('security-key')}</Title>
              </Section.TitleWrapper>
              <Section.Subtitle>
                {t(
                  'a-fido-security-key-is-a-small-hardware-device-that-can-be-used-to-unlock-your-account-just-like-a-real-key'
                )}
              </Section.Subtitle>
            </Section.Header>
            <TableList
              data={credentials?.filter(
                (credential) =>
                  credential.authenticator?.attachment === 'cross-platform'
              )}
              rowButton={(credential) => (
                <DropdownButton
                  icon="dots"
                  customStyles={styles.optionDropdown}
                >
                  <Button
                    onClick={() =>
                      handleModalOpen({
                        id: 'edit-record',
                        data: credential,
                        flow: newFlow,
                        setFlow: setNewFlow,
                      })
                    }
                    dropdown
                  >
                    {t('edit-name')}
                  </Button>
                  <Button
                    onClick={() =>
                      handleModalOpen({
                        id: 'delete-record',
                        data: credential,
                        flow: newFlow,
                        setFlow: setNewFlow,
                      })
                    }
                    dropdown
                  >
                    {t('common:delete')}
                  </Button>
                </DropdownButton>
              )}
            />
          </Section>
        ) : null}
        {config.appSettings.login.loginOptions.includes('auth-app') ? (
          <Section>
            <Section.Header
              button={{
                onClick: () =>
                  handleModalOpen({ id: 'hanko-authenticator-app' }),
                dataTestid: 'button-hanko-authenticator-app',
                customStyles: styles.inlineButton,
                isPrimary: true,
                text: t('add-new'),
              }}
              noBorder
            >
              <Section.TitleWrapper>
                <Section.Icon name="smartphone" />
                <Title type="h2">{t('hanko-authenticator')}</Title>
              </Section.TitleWrapper>
              <Section.Subtitle>
                {t(
                  'use-the-hanko-authenticator-app-on-your-phone-to-securely-access-your-account-without-a-password'
                )}
              </Section.Subtitle>
            </Section.Header>
          </Section>
        ) : null}
        <Modal
          isVisible={isModalVisible}
          content={modalContent}
          closeModal={toggleModal}
        />
      </MainWrapper>
    </>
  ) : null;
};

export default ProfilePage;
