import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Hr, SvgIcon, Title } from '../../../components/shared';
import { AlertBox } from '../../../components/shared';
import { RegisterStepsProps } from '../../../shared/data/types/register';
import BackButton from '../../../components/shared/back-button/BackButton';
import Select from '../select/Select';
import styles from './Platform.module.css';
import { useConfig } from '../../../components/shared/context/Config';

const Platform = ({ userEmail, dataTestid }: RegisterStepsProps) => {
  const { t } = useTranslation(['register_page', 'sign_in_page', 'common']);
  const { config } = useConfig();
  const [hasError, setHasError] = useState<boolean>(false);

  function handleOnClick() {
    setHasError(false);

    const submitPassword = async () =>
      await fetch(`${process.env.REACT_APP_API_URL}/foobar`, {
        method: 'GET',
      });

    submitPassword()
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
        setHasError(true);
      });
  }

  return (
    <div className={styles.content} data-testId={dataTestid}>
      <BackButton
        text={userEmail}
        to="/register"
        dataTestid="register-back-button"
      />
      <Title customStyles={styles.title}>
        {t('register_page:secure-account')}
      </Title>
      <div className={styles.iconTextWrapper}>
        <SvgIcon
          name="windowsHello"
          fillColor={config.theme.colors.brand.text}
        />
        <span className={styles.text}>{t('sign_in_page:windows-hello')}</span>
      </div>
      {hasError ? (
        <AlertBox type="error" customStyles={styles.alert}>
          {t('common:something-went-wrong')}
        </AlertBox>
      ) : null}
      <p className={styles.helloText}>
        {t(
          'register_page:set-up-windows-hello-on-this-device-to-access-your-account-without-a-password'
        )}
      </p>
      <Button
        onClick={handleOnClick}
        primary
        dataTestid="windows-hello-submit"
        customStyles={styles.submit}
      >
        {t('set-up-windows-hello')}
      </Button>
      <Hr text="or" customStyles={styles.divider} />
      <Select items={['key', 'auth-app', 'password']} page="register" />
    </div>
  );
};

export default Platform;
