import cx from 'classnames';
import styles from './Hr.module.css';
import { useConfig } from '../context/Config';

type HrProps = {
  customStyles?: string;
  text: string;
};

const Hr = ({ customStyles, text }: HrProps) => {
  const { config } = useConfig();
  return (
    <div
      className={cx(styles.wrapper, customStyles)}
      style={{ color: config.theme.colors.brand.text }}
    >
      <hr
        className={styles.hr}
        style={{ backgroundColor: config.theme.colors.secondary.light3 }}
      />
      <span className={styles.text}>{text}</span>
      <hr
        className={styles.hr}
        style={{ backgroundColor: config.theme.colors.secondary.light3 }}
      />
    </div>
  );
};

export default Hr;
