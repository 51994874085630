import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertBox, Title, ThemedIllustration } from '../../components/shared';
import styles from './RecoveryPage.module.css';
import { useKratosFlow, useQuery } from '../../shared/utils/hooks';
import { RecoveryFlow } from '@ory/kratos-client';
import { useConfig } from '../../components/shared/context/Config';
import BackButtonPlaceholder from '../../components/shared/back-button/BackButtonPlaceholder';
import RateLimitIndicator from '../../components/shared/rate-limit-indicator/RateLimitIndicator';
import { useHistory } from 'react-router';
import { useAfterLoginRedirect } from '../../shared/utils/hooks/useAfterLoginRedirect';

const RecoveryPage = () => {
  const { config } = useConfig();
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation(['common', 'sign_in_page', 'recovery_page']);
  const { normalizeKratosFlow, initializeRecoveryFlow, setNewFlow, newFlow } =
    useKratosFlow();
  const { appendQueryParamsTo } = useAfterLoginRedirect();
  const [hasError, setHasError] = useState<boolean>(false);
  const userEmail = localStorage.getItem('lastEnteredUsername');

  useEffect(() => {
    if (!userEmail || userEmail.length < 1) {
      history.push(appendQueryParamsTo('/login'));
    }
  }, []);

  const sendRecoveryLink = (): Promise<any> => {
    return fetch(newFlow.flow.ui.action, {
      method: newFlow.flow.ui.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        csrf_token: newFlow.group.default.csrf_token,
        method: 'passlink',
        email: userEmail,
        template_locale: config.appSettings.locale,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          setHasError(false);
        } else {
          setHasError(true);
        }
      })
      .catch((err) => {
        setHasError(true);
        console.error(err);
      });
  };

  useEffect(() => {
    const returnToUrl = query.get('return_to');
    initializeRecoveryFlow(returnToUrl)
      .then((res: RecoveryFlow) => {
        return normalizeKratosFlow(res);
      })
      .then((flow) => setNewFlow(flow))
      .catch((err) => {
        setHasError(true);
        console.error(err);
      });
  }, []);

  return newFlow.hasOwnProperty('flow') ? (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <BackButtonPlaceholder />
        <Title customStyles={styles.title}>
          {t('sign_in_page:check-your-email')}
        </Title>
        <ThemedIllustration name="checkEmail" />
        {hasError ? (
          <AlertBox type="error">{t('common:something-went-wrong')}</AlertBox>
        ) : null}
        <p style={{ color: config.theme.colors.brand.text }}>
          {t(
            'sign_in_page:we-send-you-a-email-with-an-link-click-these-link-and-follow-the-further-instructions'
          )}
        </p>
        <div className={styles.linkWrapper}>
          <RateLimitIndicator sendPasslinkCallback={sendRecoveryLink} />
        </div>
      </div>
    </div>
  ) : null;
};

export default RecoveryPage;
