import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Modal } from '../../..';
import { Components } from '../../../../../shared/data/types';
import styles from './AccountDelete.module.css';

const AccountDelete = ({ onCancel }: Components.Modal.AccountDeleteProps) => {
  const { t } = useTranslation('modal_delete_account');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  function handleDelete() {
    setIsLoading(true);
  }

  return (
    <div>
      <p className={styles.title}>{t('delete-account')}</p>
      <p className={styles.description}>
        {t('are-you-sure-you-want-to-delete-your-account')}
      </p>
      <Checkbox
        id="checkbox-delete-account"
        onClick={() => setIsButtonDisabled(!isButtonDisabled)}
        customStyles={styles.checkbox}
      >
        {t('yes-im-sure-delete-my-account')}
      </Checkbox>
      <Modal.ButtonWrapper
        onCancel={onCancel}
        onClick={handleDelete}
        label={t('delete-account')}
        isDisabled={isButtonDisabled}
        isLoading={isLoading}
        dataTestid="delete-account-modal"
      />
    </div>
  );
};

export default AccountDelete;
