import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';

type CookieData = {
  [key: string]: string;
};

const usePlatformAuthCookie = () => {
  const [cookies] = useCookies(['hanko_platform_authenticator']);
  const [cookieData, setCookieData] = useState<CookieData>({});

  useEffect(() => {
    try {
      const data: CookieData = JSON.parse(
        atob(cookies.hanko_platform_authenticator)
      );
      setCookieData(data);
    } catch (e) {
      // This probably means the cookie was not set.
    }
  }, [cookies.hanko_platform_authenticator]);

  const hasCredentialWithEmail = (email: string): boolean => {
    return Object.values(cookieData).includes(email);
  };

  const hasCredential = (id: string): boolean => {
    return cookieData.hasOwnProperty(id);
  };

  return { hasCredentialWithEmail, hasCredential };
};

export default usePlatformAuthCookie;
