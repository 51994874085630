import { useTranslation } from 'react-i18next';
import { SvgIcon } from '../../../..';

import styles from '../AuthenticatorApp.module.css';

const Step2 = () => {
  const { t } = useTranslation('modal_authenticator_app');

  return (
    <>
      <p className={styles.title}>{t('scan-qr-code')}</p>
      <p className={styles.desciption}>
        {t(
          'set-up-your-mobile-phone-as-an-authenticator-by-scaning-the-qr-code-with-the-hanko-authenticator-app'
        )}
      </p>
      <div className={styles.qrCode}>
        <SvgIcon name="authenticatorQRCode" />
      </div>
    </>
  );
};

export default Step2;
