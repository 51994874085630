type FormProps = {
  children: React.ReactNodeArray | React.ReactElement;
  onSubmit: () => void;
};

const Form = ({ children, onSubmit }: FormProps) => {
  function handleOnSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    onSubmit();
  }

  return <form onSubmit={handleOnSubmit}>{children}</form>;
};

export default Form;
