import styles from './TitleWrapper.module.css';

type Props = {
  children: React.ReactNodeArray;
};

const TitleWrapper = ({ children }: Props) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export default TitleWrapper;
