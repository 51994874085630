import React, { useContext, useState, useEffect, createContext } from 'react';
import { OAuth2Client } from '../../../shared/data/types/oidc';

type OidcContextProviderProps = { children: React.ReactNode };

const OidcContext = createContext<OAuth2Client[]>([] as OAuth2Client[]);

export function OidcContextProvider({ children }: OidcContextProviderProps) {
  const [clients, setClients] = useState<OAuth2Client[]>([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/oidc/clients`, { method: 'GET' })
      .then((response) => response.json())
      .then((clientList: OAuth2Client[]) => setClients(clientList))
      .catch((error) => console.error(error));
  }, []);
  return (
    <OidcContext.Provider value={clients}>{children}</OidcContext.Provider>
  );
}

export function useOidcContext() {
  const context = useContext(OidcContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
