import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Link, Tooltip } from '../../../components/shared';
import { Login } from '../../../shared/data/types';
import styles from './Select.module.css';

const Select = ({
  customStyles,
  disabledOptions,
  disabledTooltip,
  items,
  page,
  platformLabel,
}: Login.LoginListProps) => {
  const { t } = useTranslation(['sign_in_page', 'common']);
  const [keyTooltipVisibility, setKeyTooltipVisibility] = useState(false);
  const [platformTooltipVisibility, setPlatformTooltipVisibility] =
    useState(false);
  const [authTooltipVisibility, setAuthTooltipVisibility] = useState(false);
  const [passwordTooltipVisibility, setPasswordTooltipVisibility] =
    useState(false);
  const [magicTooltipVisibility, setMagicTooltipVisibility] = useState(false);
  const TOOLTIP_TEXT = t(
    'common:your-device-does-not-support-this-authentication-method'
  );

  function translateLabel(label: Login.LoginListProps['platformLabel']) {
    switch (label) {
      case 'Touch ID/Face ID':
        return t('sign_in_page:touch-id-face-id');
      case 'Windows Hello':
        return t('sign_in_page:windows-hello-0');
      case 'Android Biometrics':
        return t('sign_in_page:android-biometrics');
      case 'Platform Authenticator':
        return t('sign_in_page:platform-authenticator-0');

      default:
        return t('sign_in_page:platform-authenticator-0');
    }
  }

  function renderLink(item: string) {
    switch (item) {
      case 'platform-auth':
        return (
          <Tooltip
            isVisible={
              !!(
                platformTooltipVisibility &&
                disabledOptions?.includes('platform-auth')
              )
            }
            customStyles={styles.tooltip}
            tooltipText={TOOLTIP_TEXT}
          >
            <Link
              to={`/${page}/platform-auth`}
              customStyles={styles.linkButton}
              buttonStyle
              isDisabled={disabledOptions?.includes('platform-auth')}
              tooltip={disabledTooltip}
              onMouseEnter={() => setPlatformTooltipVisibility(true)}
              onMouseOut={() => setPlatformTooltipVisibility(false)}
              icon="fingerprint"
            >
              {translateLabel(platformLabel)}
            </Link>
          </Tooltip>
        );
      case 'auth-app':
        return (
          <Tooltip
            isVisible={
              !!(authTooltipVisibility && disabledOptions?.includes('auth-app'))
            }
            customStyles={styles.tooltip}
            tooltipText={TOOLTIP_TEXT}
          >
            <Link
              to={`/${page}/hanko-app`}
              customStyles={styles.linkButton}
              buttonStyle
              isDisabled={disabledOptions?.includes('auth-app')}
              tooltip={disabledTooltip}
              onMouseEnter={() => setAuthTooltipVisibility(true)}
              onMouseOut={() => setAuthTooltipVisibility(false)}
              icon="smartphone"
            >
              {t('sign_in_page:authenticator-app')}
            </Link>
          </Tooltip>
        );

      case 'password':
        return (
          <Tooltip
            isVisible={
              !!(
                passwordTooltipVisibility &&
                disabledOptions?.includes('password')
              )
            }
            customStyles={styles.tooltip}
            tooltipText={TOOLTIP_TEXT}
          >
            <Link
              to={`/${page}/password`}
              customStyles={styles.linkButton}
              buttonStyle
              isDisabled={disabledOptions?.includes('password')}
              tooltip={disabledTooltip}
              onMouseEnter={() => setPasswordTooltipVisibility(true)}
              onMouseOut={() => setPasswordTooltipVisibility(false)}
              icon="password"
            >
              {t('sign_in_page:password')}
            </Link>
          </Tooltip>
        );

      case 'passlink':
        return (
          <Tooltip
            isVisible={
              !!(
                magicTooltipVisibility && disabledOptions?.includes('passlink')
              )
            }
            customStyles={styles.tooltip}
            tooltipText={TOOLTIP_TEXT}
          >
            <Link
              to={`/${page}/passlink`}
              customStyles={styles.linkButton}
              buttonStyle
              isDisabled={disabledOptions?.includes('passlink')}
              tooltip={disabledTooltip}
              onMouseEnter={() => setMagicTooltipVisibility(true)}
              onMouseOut={() => setMagicTooltipVisibility(false)}
              icon="mail"
            >
              {t('sign_in_page:passlink')}
            </Link>
          </Tooltip>
        );

      case 'key':
        return (
          <Tooltip
            isVisible={
              !!(keyTooltipVisibility && disabledOptions?.includes('key'))
            }
            customStyles={styles.tooltip}
            tooltipText={TOOLTIP_TEXT}
          >
            <Link
              to={`/${page}/key`}
              customStyles={styles.linkButton}
              onMouseEnter={() => setKeyTooltipVisibility(true)}
              onMouseOut={() => setKeyTooltipVisibility(false)}
              buttonStyle
              isDisabled={disabledOptions?.includes('key')}
              tooltip={disabledTooltip}
              icon="key"
            >
              {t('sign_in_page:security-key')}
            </Link>
          </Tooltip>
        );

      default:
        return null;
    }
  }

  return (
    <ul className={cx(styles.buttonList, customStyles)}>
      {items.map((item, index) => (
        <li key={index} className={styles.listButton}>
          {renderLink(item)}
        </li>
      ))}
    </ul>
  );
};

export default Select;
