import { Suspense } from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';
// import FocusTrap from 'focus-trap-react';
import { Button, SvgIcon } from '../';
import { Components } from '../../../shared/data/types';
import {
  AuthenticatorApp,
  ButtonWrapper,
  DeletePassword,
  DeleteRecord,
  EditRecord,
  EditPassword,
  AccountDelete,
} from './content';

import styles from './Modal.module.css';
import LoadingWheel from '../loading-wheel/LoadingWheel';
import { Credential } from '../../../shared/data/types/apiData';
import { NormalizedLoginFlow } from '../../../shared/data/types/kratosFlows';
import { useConfig } from '../context/Config';

function preventBackgroundScroll(isScrollDisabled: boolean): void {
  if (isScrollDisabled) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'unset';
  }
}

function modalContent(
  content: Components.Modal.ModalProps['content'],
  closeModal: Components.Modal.ModalProps['closeModal']
) {
  switch (content?.id) {
    case 'delete-record':
      return (
        <DeleteRecord
          onCancel={closeModal}
          data={content.data || ({} as Credential)}
          flow={content.flow || ({} as NormalizedLoginFlow)}
          setFlow={content.setFlow || (() => {})}
        />
      );
    case 'edit-record':
      return (
        <EditRecord
          onCancel={closeModal}
          credential={content.data || ({} as Credential)}
          flow={content.flow || ({} as NormalizedLoginFlow)}
          setFlow={content.setFlow || (() => {})}
        />
      );
    case 'hanko-authenticator-app':
      return <AuthenticatorApp onCancel={closeModal} totalSteps={3} />;
    case 'delete-password':
      return <DeletePassword onCancel={closeModal} />;
    case 'edit-password':
      return (
        <EditPassword
          onCancel={closeModal}
          flow={content.flow || ({} as NormalizedLoginFlow)}
        />
      );
    case 'account-delete':
      return <AccountDelete onCancel={closeModal} />;

    default:
      return null;
  }
}

const Modal = ({
  closeModal,
  content,
  isVisible,
  customStyles,
}: Components.Modal.ModalProps) => {
  const { config } = useConfig();
  preventBackgroundScroll(isVisible);

  return isVisible
    ? createPortal(
        // <FocusTrap>
        <div className={styles.modalWrapper}>
          <Suspense fallback={<LoadingWheel />}>
            <div
              className={styles.overlay}
              onClick={closeModal}
              data-testid="modal-opened"
            />
            <div className={cx(styles.wrapper, customStyles)}>
              <Button
                onClick={closeModal}
                type="button"
                noStyle
                customStyles={styles.closeButton}
                dataTestid="modal-close-button"
              >
                <SvgIcon
                  name="closeCross"
                  fillColor={config.theme.colors.secondary.light3}
                />
              </Button>
              <div className={styles.content}>
                {modalContent(content, closeModal)}
              </div>
            </div>
          </Suspense>
        </div>,
        // </FocusTrap>,
        document.getElementById('app') || document.body
      )
    : null;
};

Modal.ButtonWrapper = ButtonWrapper;

export default Modal;
