import {
  Header as SectionHeader,
  Icon as SectionIcon,
  Subtitle as SectionSubtitle,
  TitleWrapper as SectionTitleWrapper,
} from './';
import styles from './Section.module.css';
import { useConfig } from '../context/Config';

type Props = {
  children: React.ReactNodeArray | React.ReactNode;
};

const Section = ({ children }: Props) => {
  const { config } = useConfig();
  return (
    <section
      className={styles.section}
      style={{ color: config.theme.colors.brand.text }}
    >
      {children}
    </section>
  );
};

Section.Header = SectionHeader;
Section.Icon = SectionIcon;
Section.TitleWrapper = SectionTitleWrapper;
Section.Subtitle = SectionSubtitle;

export default Section;
