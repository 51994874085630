import { createContext } from 'react';

const RouteContext = createContext({
  isRouteRegister: false,
  setIsRouteRegister: (value: boolean) => console.warn(value),
  shouldShowSidebarBackButton: false,
  setShouldShowSidebarBackButton: (value: boolean) => console.warn(value),
});

export default RouteContext;
