import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlertBox,
  Button,
  Form,
  PasswordBox,
  Title,
  Tooltip,
} from '../../../components/shared';
import { Link } from 'react-router-dom';
import { useKratosFlow } from '../../../shared/utils/hooks';
import { Login } from '../../../shared/data/types';
import styles from './Password.module.css';
import { useWebauthnInterceptHook } from '../../../shared/utils/hooks/useWebauthnInterceptHook';
import Select from '../select/Select';
import TranslateError from '../../../components/shared/error/TranslateError';
import { BaseError } from '../../../shared/data/errors/error';
import BackButton from '../../../components/shared/back-button/BackButton';
import { useConfig } from '../../../components/shared/context/Config';
import { useAfterLoginRedirect } from '../../../shared/utils/hooks/useAfterLoginRedirect';

const Password = ({
  getUserEmail,
  loginFlow,
  successRedirect,
}: Login.LoginStepsProps) => {
  const { t } = useTranslation(['sign_in_page', 'common']);
  const { config } = useConfig();
  const { passwordLoginFlow } = useKratosFlow();
  const { shouldDisplayWebauthnIntercept } = useWebauthnInterceptHook();
  const { appendQueryParamsTo } = useAfterLoginRedirect();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [password, setPassword] = useState<string>('');

  const [error, setError] = useState<BaseError | null>(null);
  const [isDisableTooltipVisible, setIsDisableTooltipVisible] = useState(false);

  useEffect(() => {
    setIsSubmitDisabled(password.length < 1);
  }, [password]);

  async function handleSubmit() {
    if (password.length < 1) {
      return false;
    }

    setHasError(false);
    setIsLoading(true);
    passwordLoginFlow({ loginFlow, userEmail: getUserEmail(), password })
      .then(() => {
        shouldDisplayWebauthnIntercept()
          .then((display) => {
            successRedirect(display);
          })
          .catch(() => successRedirect(false));
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
        setHasError(true);
      });
  }

  return (
    <>
      <BackButton text={getUserEmail()} to={appendQueryParamsTo('/login')} />
      <Title customStyles={styles.title}>{`${t('sign-in-to')} ${
        config.theme?.brand?.name
      }`}</Title>
      {error !== null ? (
        <AlertBox type={'error'} customStyles={styles.alert}>
          <TranslateError error={error} />
        </AlertBox>
      ) : null}
      <Form onSubmit={handleSubmit}>
        <PasswordBox
          name="user-password"
          showPassword
          hasFocus
          placeholder={t('common:password')}
          onChange={(e) => setPassword(e.target.value)}
          scoreUpdated={() => {}}
        />
        {config.appSettings.login.recoveryEnabled ? (
          <div className={styles.linkWrapper}>
            <Link
              to={appendQueryParamsTo('/recovery')}
              className={styles.link}
              style={{ color: config.theme.colors.secondary.light2 }}
            >
              {t('common:forgot-your-password')}
            </Link>
          </div>
        ) : null}
        <Tooltip
          isVisible={isSubmitDisabled && isDisableTooltipVisible}
          customStyles={styles.disableTooltip}
          tooltipText={t('sign_in_page:password-shouldn-t-be-empty')}
        >
          <Button
            onClick={() => {}}
            type="submit"
            dataTestid="password-continue-button"
            primary
            loading={isLoading}
            customStyles={styles.continueButton}
            isDisabled={isSubmitDisabled}
            onMouseEnter={() => setIsDisableTooltipVisible(true)}
            onMouseOut={() => setIsDisableTooltipVisible(false)}
            onFocus={() => setIsDisableTooltipVisible(true)}
            onBlur={() => setIsDisableTooltipVisible(false)}
          >
            {hasError ? t('common:retry') : t('common:continue')}
          </Button>
        </Tooltip>
      </Form>
      <Select
        excludeLoginOption={'password'}
        loginFlow={loginFlow}
        getUserEmail={getUserEmail}
        successRedirect={successRedirect}
      />
    </>
  );
};

export default Password;
