import { SettingsFlow } from '@ory/kratos-client';
import { Credential } from '../../data/types/apiData';
import { useKratosFlow } from './index';
import usePlatformAuthCookie from './usePlatformAuthCookie';
import { isUserVerifyingPlatformAuthenticatorAvailable } from '@teamhanko/hanko-webauthn';

const useWebauthnInterceptHook = () => {
  const { initializeSettingsFlow, normalizeKratosFlow } = useKratosFlow();
  const { hasCredential } = usePlatformAuthCookie();
  const localStorageKey = 'skip_webauthn_intercept';

  const shouldDisplayWebauthnIntercept = async (): Promise<boolean> => {
    const response: SettingsFlow = await initializeSettingsFlow();
    const settingsFlow = normalizeKratosFlow(response);
    const credentials: Credential[] = JSON.parse(
      settingsFlow.group?.hanko?.credentials
    );
    const platformAuthenticatorAvailable =
      await isUserVerifyingPlatformAuthenticatorAvailable();
    return (
      platformAuthenticatorAvailable &&
      !credentials?.filter((credential) => hasCredential(credential.id))
        .length &&
      !(localStorage.getItem(localStorageKey) === 'on')
    );
  };

  const skipWebauthnIntercept = (skip: boolean) =>
    localStorage.setItem(localStorageKey, skip ? 'on' : 'off');

  return {
    shouldDisplayWebauthnIntercept,
    skipWebauthnIntercept,
  };
};

export { useWebauthnInterceptHook };
