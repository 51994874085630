import styles from './Screen.module.css';
import { useConfig } from '../context/Config';
import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle<{ fontFamily: string }>`
    body {
        font-family: ${(props) => props.fontFamily};
    }
`;

type Props = {
  children: React.ReactNode;
};

const Screen = (props: Props) => {
  const { isConfigLoaded, config } = useConfig();

  return isConfigLoaded ? (
    <>
      <GlobalStyle fontFamily={config.theme.fontFamily} />
      <div
        className={styles.App}
        id="app"
        style={{ color: config.theme.colors.secondary.default }}
      >
        {props.children}
      </div>
    </>
  ) : null;
};

export default Screen;
