import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import useSessionLogout from '../../shared/utils/hooks/useSessionLogout';
import { UserContext } from '../shared/context';
import { KRATOS_SESSION_WHOAMI } from '../../shared/utils/apiConstants';
import { useAfterLoginRedirect } from '../../shared/utils/hooks/useAfterLoginRedirect';

type SessionWrapperProps = {
  children: any;
  shouldRedirectValidUser?: boolean;
};

const SessionWrapper = ({
  children,
  shouldRedirectValidUser,
}: SessionWrapperProps) => {
  const { makeLoginReturnToPath } = useAfterLoginRedirect();
  const { userLogout } = useSessionLogout();
  const { setUserEmail, setUserName, setUserLastName, setUserDegree } =
    useContext(UserContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const requestSession = async () =>
      await fetch(
        `${process.env.REACT_APP_KRATOS_PUBLIC_URL}/${KRATOS_SESSION_WHOAMI}`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );

    requestSession()
      .then((response) => {
        if (!shouldRedirectValidUser && response.status !== 200) {
          sessionStorage.clear();
          history.push(makeLoginReturnToPath(location.pathname));
          return;
        }

        return response.json();
      })
      .then((response) => {
        if (response?.identity?.traits) {
          const {
            identity: { traits },
          } = response;

          setUserEmail(traits?.email);
          setUserName(traits?.name.first);
          setUserLastName(traits?.name.last);
          setUserDegree(traits?.name['academic-degree']);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [
    userLogout,
    setUserEmail,
    setUserName,
    setUserLastName,
    setUserDegree,
    shouldRedirectValidUser,
    history,
  ]);

  return children;
};

export default SessionWrapper;
