import styled from 'styled-components';
import { useConfig } from '../../context/Config';
import { ReactComponent as TiltedEnvelopeIllustrationTop } from '../../../../assets/icons/envelope-illustration/tilted-envelope/topSide.svg';
import { ReactComponent as TiltedEnvelopeIllustrationBottom } from '../../../../assets/icons/envelope-illustration/tilted-envelope/bottomSide.svg';
import { ReactComponent as TiltedEnvelopeIllustrationLeft } from '../../../../assets/icons/envelope-illustration/tilted-envelope/leftSide.svg';
import { ReactComponent as TiltedEnvelopeIllustrationRight } from '../../../../assets/icons/envelope-illustration/tilted-envelope/rightSide.svg';

import { ReactComponent as OpenEnvelopeIllustrationTop } from '../../../../assets/icons/envelope-illustration/envelope/envelope-top.svg';
import { ReactComponent as OpenEnvelopeIllustrationRight } from '../../../../assets/icons/envelope-illustration/envelope/envelope-right.svg';
import { ReactComponent as OpenEnvelopeIllustrationBottom } from '../../../../assets/icons/envelope-illustration/envelope/envelope-bottom.svg';
import { ReactComponent as OpenEnvelopeIllustrationLeft } from '../../../../assets/icons/envelope-illustration/envelope/envelope-left.svg';

import { ReactComponent as LetterButtonIllustration } from '../../../../assets/icons/envelope-illustration/envelope/letter/letter-button.svg';
import { ReactComponent as LetterLogoIllustration } from '../../../../assets/icons/envelope-illustration/envelope/letter/letter-logo.svg';
import { ReactComponent as LetterPaperIllustration } from '../../../../assets/icons/envelope-illustration/envelope/letter/letter-paper.svg';
import { ReactComponent as LetterTextIllustration } from '../../../../assets/icons/envelope-illustration/envelope/letter/letter-text.svg';

import { ReactComponent as NotificationBackgroundIllustration } from '../../../../assets/icons/envelope-illustration/envelope/notification/notification-background.svg';
import { ReactComponent as NotificationNumberIllustration } from '../../../../assets/icons/envelope-illustration/envelope/notification/notification-number.svg';

import { ReactComponent as LinesArcIllustration } from '../../../../assets/icons/envelope-illustration/lines/lines-arc.svg';
import { ReactComponent as LinesTopIllustration } from '../../../../assets/icons/envelope-illustration/lines/lines-top.svg';
import { ReactComponent as LinesMidIllustration } from '../../../../assets/icons/envelope-illustration/lines/lines-mid.svg';
import { ReactComponent as LinesBottomIllustration } from '../../../../assets/icons/envelope-illustration/lines/lines-bottom.svg';

import { ReactComponent as BackgroundIllustration } from '../../../../assets/icons/envelope-illustration/background.svg';

const Wrapper = styled.div`
  position: relative;
  max-width: 350px;
  height: 150px;
`;

const TiltedEnvelopeWrapper = styled.div`
  position: relative;
  top: 64px;
  left: 78px;
`;

const TiltedEnvelopeBottom = styled(TiltedEnvelopeIllustrationBottom)<{
  fill: any;
}>`
  position: absolute;
  z-index: 0;
  top: 20px;
  left: 7px;
  fill: ${({ fill }) => (fill ? fill : 'none')};
`;

const TiltedEnvelopeTop = styled(TiltedEnvelopeIllustrationTop)<{ fill: any }>`
  position: absolute;
  z-index: 2;
  fill: ${({ fill }) => (fill ? fill : 'none')};
`;

const TiltedEnvelopeLeft = styled(TiltedEnvelopeIllustrationLeft)<{
  fill: any;
}>`
  position: absolute;
  top: 28px;
  z-index: 1;
  fill: ${({ fill }) => (fill ? fill : 'none')};
`;

const TiltedEnvelopeRight = styled(TiltedEnvelopeIllustrationRight)<{
  fill: any;
}>`
  position: absolute;
  left: 43px;
  z-index: 1;
  fill: ${({ fill }) => (fill ? fill : 'none')};
`;

const OpenEnvelopeWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 212px;
`;

const OpenEnvelopeTop = styled(OpenEnvelopeIllustrationTop)<{ fill: any }>`
  position: absolute;
  z-index: 0;
  fill: ${({ fill }) => (fill ? fill : 'none')};
`;

const OpenEnvelopeBottom = styled(OpenEnvelopeIllustrationBottom)<{
  fill: any;
}>`
  position: absolute;
  top: 47px;
  z-index: 1;
  fill: ${({ fill }) => (fill ? fill : 'none')};
`;

const OpenEnvelopeLeft = styled(OpenEnvelopeIllustrationLeft)<{ fill: any }>`
  position: absolute;
  top: 26px;
  z-index: 1;
  fill: ${({ fill }) => (fill ? fill : 'none')};
`;

const OpenEnvelopeRight = styled(OpenEnvelopeIllustrationRight)<{
  fill: any;
}>`
  position: absolute;
  top: 26px;
  left: 38px;
  z-index: 1;
  fill: ${({ fill }) => (fill ? fill : 'none')};
`;

const LetterWrapper = styled.div`
  position: absolute;
`;

const LetterButton = styled(LetterButtonIllustration)`
  position: absolute;
  top: 40px;
  left: 30px;
  z-index: 2;
`;

const LetterLogo = styled(LetterLogoIllustration)`
  position: absolute;
  top: 25px;
  left: 18px;
  z-index: 2;
`;

const LetterPaper = styled(LetterPaperIllustration)`
  position: absolute;
  top: 22px;
  left: 15px;
  z-index: 1;
`;

const LetterText = styled(LetterTextIllustration)`
  position: absolute;
  top: 33px;
  left: 22px;
  z-index: 2;
`;

const NotificationCountWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: 55px;
  z-index: 2;
`;

const NotificationBackground = styled(NotificationBackgroundIllustration)`
  position: absolute;
  z-index: 1;
`;

const NotificationNumber = styled(NotificationNumberIllustration)`
  position: absolute;
  top: 8px;
  left: 10px;
  z-index: 1;
`;

const Background = styled(BackgroundIllustration)`
  position: absolute;
  z-index: 0;
  top: 14px;
  left: 121px;
`;

const Arc = styled(LinesArcIllustration)`
  position: absolute;
  top: 0;
  left: 116px;
  z-index: 0;
`;

const LinesWrapper = styled.div`
  position: absolute;
  z-index: 1;
`;

const LinesTop = styled(LinesTopIllustration)`
  position: absolute;
  top: 114px;
  left: 42px;
`;

const LinesMid = styled(LinesMidIllustration)`
  position: absolute;
  top: 122px;
  left: 35px;
`;

const LinesBottom = styled(LinesBottomIllustration)`
  position: absolute;
  top: 124px;
  left: 44px;
`;

const CheckEmailIllustration = () => {
  const {
    config: { theme },
  } = useConfig();
  return (
    <Wrapper>
      <Background fill={theme.colors.secondary.light4} />
      <LinesWrapper>
        <Arc fill={theme.colors.secondary.default} />
        <LinesTop fill={theme.colors.secondary.default} />
        <LinesMid fill={theme.colors.secondary.default} />
        <LinesBottom fill={theme.colors.secondary.default} />
      </LinesWrapper>
      <TiltedEnvelopeWrapper>
        <TiltedEnvelopeTop fill={theme.colors.secondary.light3} />
        <TiltedEnvelopeBottom fill={theme.colors.secondary.light1} />
        <TiltedEnvelopeLeft fill={theme.colors.secondary.light2} />
        <TiltedEnvelopeRight fill={theme.colors.secondary.light2} />
      </TiltedEnvelopeWrapper>
      <OpenEnvelopeWrapper>
        <LetterWrapper>
          <LetterButton fill={theme.colors.brand.default} />
          <LetterLogo fill={theme.colors.brand.default} />
          <LetterPaper fill="#ffffff" />
          <LetterText fill={theme.colors.secondary.light4} />
          <NotificationCountWrapper>
            <NotificationBackground fill={theme.colors.brand.bright} />
            <NotificationNumber fill={theme.colors.brand.default} />
          </NotificationCountWrapper>
        </LetterWrapper>
        <OpenEnvelopeBottom fill={theme.colors.secondary.light1} />
        <OpenEnvelopeTop fill={theme.colors.secondary.light3} />
        <OpenEnvelopeLeft fill={theme.colors.secondary.light2} />
        <OpenEnvelopeRight fill={theme.colors.secondary.light2} />
      </OpenEnvelopeWrapper>
    </Wrapper>
  );
};

export default CheckEmailIllustration;
