import cx from 'classnames';
import { Shield } from './shield/Shield';
import { Stars } from './stars/Stars';
import { Api } from '../../../shared/data/types';
import { SecurityBadge as SecurityBadgeTypes } from '../../../shared/data/types';

import styles from './SecurityBadge.module.css';

type Props = {
  color?: SecurityBadgeTypes.Style;
  customStyles?: string;
  security?: Api.UserSecurity;
  stars?: 1 | 2 | 3;
};

type Security = Api.UserSecurity;

enum TotalStars {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

function dynamicStars({ auth }: Security) {
  if (
    (auth.hasAppAuth || auth.hasPlatformAuth) &&
    auth.hasSecurityKey &&
    auth.isPwless
  ) {
    return TotalStars.THREE;
  } else if ((auth.hasAppAuth || auth.hasPlatformAuth) && auth.hasSecurityKey) {
    return TotalStars.TWO;
  } else {
    return TotalStars.ONE;
  }
}

function dynamicColor(auth: Security) {
  const totalStars = dynamicStars(auth) || 0;

  if (totalStars > 1) {
    return 'positive';
  }

  return 'negative';
}

const SecurityBadge = ({ color, customStyles, security, stars }: Props) => (
  <div className={cx(styles.wrapper, customStyles)}>
    <Shield color={color || (security ? dynamicColor(security) : undefined)} />
    <Stars
      isPwless={security?.auth.isPwless}
      stars={stars || (security ? dynamicStars(security) : undefined)}
      color={color || (security ? dynamicColor(security) : undefined)}
    />
  </div>
);

export default SecurityBadge;
