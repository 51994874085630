import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlertBox,
  Breadcrumbs,
  Button,
  Form,
  Link,
  MainWrapper,
  Section,
  Textbox,
  Title,
} from '../../components/shared';
import { useKratosFlow } from '../../shared/utils/hooks';
import { RouteContext, UserContext } from '../../components/shared/context';
import styles from './AccountPage.module.css';
import { BaseError, SessionExpiredError } from '../../shared/data/errors/error';
import { useHistory } from 'react-router';
import { SettingsFlow } from '@ory/kratos-client';
import { useAfterLoginRedirect } from '../../shared/utils/hooks/useAfterLoginRedirect';

const AccountPage = () => {
  const { t } = useTranslation(['account_page', 'profile_page', 'common']);
  const {
    profileSettingsFlow,
    initializeSettingsFlow,
    setNewFlow,
    normalizeKratosFlow,
    newFlow,
  } = useKratosFlow();
  const [isUsernameLoading, setIsUsernameLoading] = useState<boolean>(false);
  const [usernameHasError, setUsernameHasError] = useState<boolean>(false);
  const [dataUpdateSuccess, setDataUpdateSuccess] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const { userEmail, userName, userLastName, userDegree } =
    useContext(UserContext);
  const { setShouldShowSidebarBackButton } = useContext(RouteContext);
  const [isPageDataLoaded, setIsPageDataLoaded] = useState<boolean>(false);
  const history = useHistory();
  const { appendQueryParamsTo, makeLoginReturnToPath } =
    useAfterLoginRedirect();

  useEffect(() => {
    initializeSettingsFlow()
      .then((flow: SettingsFlow) => {
        setNewFlow(normalizeKratosFlow(flow));
        setIsPageDataLoaded(true);
      })
      .catch((error: BaseError) => {
        if (error instanceof SessionExpiredError) {
          history.push(makeLoginReturnToPath('/account'));
        }
      });
  }, []);

  useEffect(() => {
    setShouldShowSidebarBackButton(true);
  }, []);

  const handleSubmitUsername = () => {
    setIsUsernameLoading(false);
    setDataUpdateSuccess(false);
    setUsernameHasError(false);

    profileSettingsFlow({
      settingsFlow: newFlow,
      userEmail,
      firstName,
      lastName,
      title,
    })
      .then(() => {
        setIsUsernameLoading(false);
        return setDataUpdateSuccess(true);
      })
      .catch((error: BaseError) => {
        if (error instanceof SessionExpiredError) {
          history.push(makeLoginReturnToPath('/account'));
        } else {
          setUsernameHasError(true);
          setIsUsernameLoading(false);
        }
      });
  };

  return isPageDataLoaded ? (
    <>
      <Breadcrumbs
        currentPage={t('account-settings')}
        customStyles={styles.breadcrumbs}
      >
        <Link to={appendQueryParamsTo('/profile')}>
          {t('profile_page:account_security')}
        </Link>
      </Breadcrumbs>
      <MainWrapper>
        <Title customStyles={styles.title}>{t('account-settings')}</Title>
        <Section>
          <Section.Header small>
            <Title type="h2">{t('account-details')}</Title>
          </Section.Header>
          <Form onSubmit={handleSubmitUsername}>
            <div className={styles.textboxWrapper}>
              <Textbox
                name="user-email"
                label={t('common:email')}
                defaultValue={userEmail}
                disabled
                dataTestid="account-user-email"
                customStyles={styles.textbox}
                hideClearButton
              />
            </div>
            <div className={styles.textboxWrapper}>
              <Textbox
                name="personal-title"
                label={t('title')}
                onLoad={(val) => setTitle(val)}
                onChange={(e) => setTitle(e.target.value)}
                defaultValue={userDegree}
                dataTestid="account-personal-title"
                hideClearButton
                customStyles={styles.textbox}
                maxLength={64}
              />
            </div>
            <div className={styles.inlineInputWrapper}>
              <div className={styles.inlineInput}>
                <Textbox
                  name="personal-first-name"
                  label={t('first-name')}
                  onLoad={(val) => setFirstName(val)}
                  onChange={(e) => setFirstName(e.target.value)}
                  defaultValue={userName}
                  dataTestid="account-first-name"
                  hideClearButton
                  customStyles={styles.textbox}
                  required
                  maxLength={64}
                />
              </div>
              <div className={styles.inlineInput}>
                <Textbox
                  name="personal-last-name"
                  label={t('last-name')}
                  onLoad={(val) => setLastName(val)}
                  onChange={(e) => setLastName(e.target.value)}
                  defaultValue={userLastName}
                  dataTestid="account-last-name"
                  hideClearButton
                  customStyles={styles.textbox}
                  required
                  maxLength={64}
                />
              </div>
              <Button
                onClick={() => {}}
                dataTestid="new-email-submit-button"
                primary
                type="submit"
                loading={isUsernameLoading}
                customStyles={styles.cta}
              >
                {t('common:submit')}
              </Button>
            </div>
          </Form>
          {usernameHasError ? (
            <AlertBox type="error" customStyles={styles.alert}>
              {t('common:something-went-wrong')}
            </AlertBox>
          ) : null}
          {dataUpdateSuccess ? (
            <AlertBox type="success" customStyles={styles.alert}>
              {t('common:username-updated-successfully')}
            </AlertBox>
          ) : null}
        </Section>
      </MainWrapper>
    </>
  ) : null;
};

export default AccountPage;
