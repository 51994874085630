import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Components } from '../../../shared/data/types';
import { Credential } from '../../../shared/data/types/apiData';
import styles from './TableList.module.css';

type Props = {
  data: Credential[];
  rowButton?: (
    id: Components.Modal.EditRecordProps['credential']
  ) => React.ReactNode;
};

const TableList = ({ data, rowButton }: Props) => {
  const { t } = useTranslation('common');

  return (
    <table className={styles.table}>
      <tbody className={styles.list}>
        {data?.map((item, index) => (
          <tr
            key={index}
            className={cx(styles.row, { [styles.noCategory]: !item.category })}
          >
            <td className={styles.item}>{item.name}</td>
            {/* {item?.category ? (
              <td
                className={cx(styles.item, styles.label)}
                style={{ backgroundColor: item?.category?.color }}
              >
                {isSmallScreen ? item.category.textSmall : item.category.text}
              </td>
            ) : MID_SIZE_SCREEN ? null : (
              <td></td>
            )} */}
            <td className={styles.item}>
              {`${t('registered-on', { val: item.createdAt })}`}
            </td>
            <td className={styles.item}>
              {`${t('last-used', { val: item.lastUsed })} `}
            </td>
            {rowButton ? (
              <td className={styles.optionsButton}>{rowButton(item)}</td>
            ) : null}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableList;
