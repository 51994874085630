import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { SettingsFlow } from '@ory/kratos-client';
import { RouteContext, UserContext } from '../../components/shared/context';
import {
  AlertBox,
  Button,
  Form,
  PasswordBox,
  Title,
} from '../../components/shared';
import {
  useKratosFlow,
  usePasswordSecurityText,
} from '../../shared/utils/hooks';
import styles from './OnboardingPage.module.css';
import { useWebauthnInterceptHook } from '../../shared/utils/hooks/useWebauthnInterceptHook';
import { useAfterLoginRedirect } from '../../shared/utils/hooks/useAfterLoginRedirect';
import {
  BaseError,
  PasswordTooSimilarToIdentifierError,
  SessionExpiredError,
} from '../../shared/data/errors/error';
import { useConfig } from '../../components/shared/context/Config';

const useQuery = () => new URLSearchParams(useLocation().search);

const OnboardingPage = () => {
  const { t } = useTranslation(['common', 'error']);
  const { config } = useConfig();
  const { userEmail } = useContext(UserContext);
  const history = useHistory();
  const { shouldDisplayWebauthnIntercept } = useWebauthnInterceptHook();
  const {
    redirectTo,
    redirectToIntercept,
    getReturnToUrlFromRedirectUrl,
    appendQueryParamsTo,
  } = useAfterLoginRedirect();
  const query = useQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentSecurityLevel, setCurrentSecurityLevel] =
    useState<any>(undefined);
  const [currentBreaches, setCurrentBreaches] = useState<any>(undefined);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    t('common:something-went-wrong')
  );
  const [password, setPassword] = useState<string>('');
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const { setIsRouteRegister } = useContext(RouteContext);

  const {
    normalizeKratosFlow,
    setNewFlow,
    newFlow,
    getSettingsFlow,
    initializeSettingsFlow,
    passwordSettingsFlow,
  } = useKratosFlow();
  const { smallTip } = usePasswordSecurityText({
    securityLevel: currentSecurityLevel,
    totalBreaches: currentBreaches,
  });

  function handleScoreUpdate({
    securityLevel,
    totalBreaches,
  }: {
    securityLevel: any;
    totalBreaches: any;
  }) {
    setCurrentSecurityLevel(securityLevel);
    setCurrentBreaches(totalBreaches);
  }

  function handleRedirect(showIntercept: boolean) {
    const redirectToUrl = getReturnToUrlFromRedirectUrl(redirectUrl);
    showIntercept
      ? redirectToIntercept(redirectToUrl)
      : redirectTo(redirectToUrl);
  }

  function handleSubmit() {
    setHasError(false);
    setIsLoading(true);
    passwordSettingsFlow({ settingsFlow: newFlow, password })
      .then(() => {
        shouldDisplayWebauthnIntercept()
          .then((display) => {
            handleRedirect(display);
          })
          .catch(() => {
            handleRedirect(false);
          });
      })
      .catch((error: BaseError) => {
        console.error(error);
        if (error instanceof SessionExpiredError) {
          history.push(appendQueryParamsTo('/login'));
        } else if (error instanceof PasswordTooSimilarToIdentifierError) {
          setHasError(true);
          setErrorMessage(t('error:password-too-similar-to-identifier'));
          setIsLoading(false);
        } else {
          console.error(error);
          setHasError(true);
          setIsLoading(false);
        }
      });
  }

  useEffect(() => {
    setIsRouteRegister(false);

    const flowId = query.get('flow');
    const returnToQueryParam = query.get('return_to');

    let getFlowFunction: Promise<SettingsFlow>;
    if (flowId !== null && flowId.length > 0) {
      // After verification and recovery Kratos will initialize a new settings flow. The return_to parameter in this
      // case is still attached to the flow's request_url.
      getFlowFunction = getSettingsFlow(flowId);
    } else {
      // After Passlink login Kratos won't initialize a settings flow, but append the return_to parameter to the url.
      getFlowFunction = initializeSettingsFlow(returnToQueryParam);
    }

    getFlowFunction
      .then((res: SettingsFlow) => setNewFlow(normalizeKratosFlow(res)))
      .catch((error: BaseError) => {
        if (error instanceof SessionExpiredError) {
          history.push(appendQueryParamsTo('/login'));
        } else {
          setHasError(true);
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    if (redirectUrl === '' && newFlow.hasOwnProperty('flow')) {
      setRedirectUrl(newFlow.flow.request_url);
      localStorage.setItem(
        'lastUsedUsername',
        newFlow.group.profile['traits.email']
      );
    }
  }, [newFlow]);

  useEffect(() => {
    if (!newFlow.hasOwnProperty('flow')) return;

    if (!config.appSettings.login.loginOptions.includes('password')) {
      shouldDisplayWebauthnIntercept()
        .then((display) => {
          handleRedirect(display);
        })
        .catch(() => {
          handleRedirect(false);
        });
    } else {
      setIsPageLoaded(true);
    }
  }, [redirectUrl]);

  return isPageLoaded ? (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div
          className={styles.email}
          style={{ color: config.theme.colors.brand.text }}
        >{`${t('welcome')} ${userEmail}`}</div>
        <Title customStyles={styles.title}>
          {t('modal_edit_password:set-new-password')}
        </Title>
        {hasError ? <AlertBox type="error">{errorMessage}</AlertBox> : null}
        <Form onSubmit={() => {}}>
          <PasswordBox
            name="register-new-password"
            onChange={(e) => setPassword(e.target.value)}
            scoreUpdated={({ securityLevel, totalBreaches }) =>
              handleScoreUpdate({ securityLevel, totalBreaches })
            }
            hasFocus
            passwordStength
            showPassword
            validatePattern
            placeholder={t('common:new-password')}
          />
          <div
            className={styles.smallTipWrapper}
            style={{ color: config.theme.colors.secondary.light1 }}
          >
            <p className={styles.smallTip}>{smallTip}</p>
            <Button
              onClick={handleSubmit}
              customStyles={styles.submit}
              primary
              type="submit"
              isDisabled={
                isLoading ||
                password.length < 1 ||
                currentSecurityLevel === 'short'
              }
              loading={isLoading}
              dataTestid={`reset-password-submit`}
            >
              {t('common:continue')}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  ) : null;
};

export default OnboardingPage;
