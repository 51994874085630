import { HYDRA_LOGOUT } from '../apiConstants';

/**
 * Terminates the user session and redirects the user
 */
const useSessionLogout = () => {
  async function userLogout() {
    try {
      sessionStorage.clear();
      window.location.href = `${process.env.REACT_APP_KRATOS_PUBLIC_URL}/${HYDRA_LOGOUT}`;
    } catch (error) {
      console.error(error);
    }
  }

  return { userLogout };
};

export default useSessionLogout;
