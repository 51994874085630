import styles from './MainApp.module.css';
import { useConfig } from '../shared/context/Config';

type Props = {
  children: React.ReactNodeArray | React.ReactNode;
};

const MainApp = ({ children }: Props) => {
  const { config } = useConfig();

  return (
    <div
      className={styles.mainApp}
      style={{ backgroundColor: config.theme?.colors?.brand?.appBackground }}
    >
      {children}
    </div>
  );
};

export default MainApp;
