import { useState } from 'react';
import cx from 'classnames';
import { Components } from '../../../shared/data/types';
import styles from './Checkbox.module.css';
import { useConfig } from '../context/Config';

const Checkbox = ({
  children,
  customStyles,
  dataTestid,
  id,
  onClick,
  size,
}: Components.CheckboxProps) => {
  const { config } = useConfig();
  const [isCheckboxFocused, setIsCheckboxFocused] = useState<boolean>(false);

  return (
    <div className={cx(styles.wrapper, customStyles)}>
      <div className={styles.checkboxWrapper}>
        <input
          type="checkbox"
          id={id}
          name={id}
          onFocus={() => setIsCheckboxFocused(true)}
          onBlur={() => setIsCheckboxFocused(false)}
          className={styles.checkboxInput}
          data-testid={dataTestid || id}
          onClick={(e) => onClick && onClick(e.currentTarget.checked)}
          style={{
            width: `${size}px`,
            height: `${size}px`,
            borderColor: config.theme.colors.secondary.default,
          }}
        />
        <div
          className={cx(styles.checkbox, {
            [styles.checkboxFocus]: isCheckboxFocused,
          })}
          style={{ width: `${size}px`, height: `${size}px` }}
        />
      </div>
      <label htmlFor={id} className={styles.label}>
        {children}
      </label>
    </div>
  );
};

Checkbox.defaultProps = {
  size: 20,
};

export default Checkbox;
