import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Components } from '../../../../../shared/data/types';
import Checkbox from '../../../checkbox/Checkbox';
import Modal from '../../Modal';
import styles from './DeletePassword.module.css';

const DeletePassword = ({ onCancel }: Components.Modal.DeletePasswordProps) => {
  const { t } = useTranslation('modal_delete_password');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  function handleDeletePassword() {
    // @TODO: send data to the DB
    console.log('delete password');
    onCancel();
  }

  return (
    <>
      <p className={styles.title}>{t('delete-your-password')}</p>
      <p className={styles.description}>
        {t(
          'you-have-activated-passwordless-authentication-therefore-you-can-now-delete-your-password-this-guarantees-the-best-security-for-your-account'
        )}
      </p>
      <Checkbox
        id="checkbox-delete-password"
        onClick={() => setIsButtonDisabled(!isButtonDisabled)}
        customStyles={styles.checkbox}
      >
        {t('yes-im-sure-delete-my-password')}
      </Checkbox>
      <Modal.ButtonWrapper
        isDisabled={isButtonDisabled}
        onCancel={onCancel}
        onClick={handleDeletePassword}
        label={t('profile_page:delete-password')}
        dataTestid="modal-delete-password"
      />
    </>
  );
};

export default DeletePassword;
