import cx from 'classnames';
import { SvgIcon } from '../../../shared';
import { IconsType } from '../../../../shared/data/types';

import styles from './Icon.module.css';

type Props = {
  customStyles?: string;
  fillColor?: string;
  name: IconsType['name'];
};

const Icon = ({ customStyles, fillColor, name }: Props) => {
  return (
    <div className={cx(styles.icon, customStyles)}>
      <SvgIcon name={name} fillColor={fillColor} />
    </div>
  );
};

export default Icon;
