import { createContext } from 'react';

const UserContext = createContext({
  userEmail: '',
  setUserEmail: (value: string) => console.warn(value),
  userName: '',
  setUserName: (value: string) => console.warn(value),
  userLastName: '',
  setUserLastName: (value: string) => console.warn(value),
  userDegree: '',
  setUserDegree: (value: string) => console.warn(value),
});

export default UserContext;
