import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKratosFlow, useQuery } from '../../shared/utils/hooks';
import { OverlayContext } from '../../components/shared/context';
import { AlertBox, Button, Title } from '../../components/shared';
import styles from './WebauthnInterceptPage.module.css';
import { SettingsFlow } from '@ory/kratos-client';
import { useWebauthnInterceptHook } from '../../shared/utils/hooks/useWebauthnInterceptHook';
import { useAfterLoginRedirect } from '../../shared/utils/hooks/useAfterLoginRedirect';
import { BaseError, SessionExpiredError } from '../../shared/data/errors/error';
import { useHistory } from 'react-router';
import { getPlatformAuthenticatorAlias } from '@teamhanko/hanko-webauthn';
import { useConfig } from '../../components/shared/context/Config';
import TitleWithIcon from '../../components/shared/title/TitleWithIcon';
import cx from 'classnames';
import BackButtonPlaceholder from '../../components/shared/back-button/BackButtonPlaceholder';
import { useMedia } from 'react-media';
import { MEDIA_QUERIES } from '../../shared/utils/mediaQueries';

const WebauthnInterceptPage = () => {
  const { t } = useTranslation(['common', 'webauthn_intercept_page']);
  const {
    newFlow,
    webauthnSettingsFlow,
    setNewFlow,
    normalizeKratosFlow,
    initializeSettingsFlow,
  } = useKratosFlow();
  const query = useQuery();
  const { skipWebauthnIntercept } = useWebauthnInterceptHook();
  const history = useHistory();
  const { config } = useConfig();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSkipLoading, setIsSkipLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const { redirectTo } = useAfterLoginRedirect();
  const { setIsOverlayVisible } = useContext(OverlayContext);
  const userBiometricsLabel = getPlatformAuthenticatorAlias(
    navigator.userAgent
  );
  const isMobileSmallScreen = useMedia({ query: MEDIA_QUERIES.mobileSmall });
  const handleRedirect = (shouldShowLoading: boolean = true) => {
    if (shouldShowLoading) {
      setIsLoading(false);
      setIsSkipLoading(true);
    }
    redirectTo(query.get('return_to'));
  };

  const handleDontAskAgain = () => {
    skipWebauthnIntercept(true);
    return handleRedirect(false);
  };

  const handleSubmit = () => {
    setHasError(false);
    setIsLoading(true);
    setIsSkipLoading(false);
    setIsOverlayVisible(true);

    webauthnSettingsFlow({ attachment: 'platform', settingsFlow: newFlow })
      .then(() => {
        handleRedirect(false);
      })
      .catch((error: BaseError) => {
        if (error instanceof SessionExpiredError) {
          history.push('/login');
        } else {
          setHasError(true);
          setIsLoading(false);
        }
      })
      .finally(() => setIsOverlayVisible(false));
  };

  useEffect(() => {
    initializeSettingsFlow()
      .then((flow: SettingsFlow) => {
        setNewFlow(normalizeKratosFlow(flow));
      })
      .catch((error: BaseError) => {
        if (error instanceof SessionExpiredError) {
          history.push('/login');
        } else {
          setHasError(true);
          setIsLoading(false);
          setIsSkipLoading(false);
        }
      });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <BackButtonPlaceholder />
        <Title customStyles={styles.title}>
          {t('webauthn_intercept_page:login-faster-next-time')}
        </Title>
        <hr
          className={styles.titleDivider}
          style={{ backgroundColor: config.theme.colors.secondary.light4 }}
        />
        <TitleWithIcon
          iconFillColor={config.theme.colors.brand.default}
          iconName={
            userBiometricsLabel === 'Windows Hello'
              ? 'windowsHello'
              : 'fingerprint'
          }
        >
          {t('webauthn_intercept_page:enable-biometrics', {
            userBiometricsLabel,
          })}
        </TitleWithIcon>
        {hasError ? (
          <AlertBox type="error">{t('common:something-went-wrong')}</AlertBox>
        ) : null}
        <p
          className={styles.text}
          style={{ color: config.theme.colors.brand.text }}
        >
          {t(
            'webauthn_intercept_page:your-device-supports-passwordless-authentication-with-touch-id-login-securely-on-this-device-without-your-password-you-can-toggle-touch-id-at-any-time-in-your-security-settings-we-will-never-see-or-store-your-biometrics',
            { userBiometricsLabel }
          )}
        </p>
        <div className={styles.buttonWrapper}>
          <Button
            onClick={() => handleRedirect()}
            loading={isSkipLoading}
            customStyles={cx(styles.button, styles.skipButton)}
          >
            {t('webauthn_intercept_page:skip')}
          </Button>
          <Button
            onClick={handleSubmit}
            customStyles={styles.button}
            primary
            type="submit"
            loading={isLoading}
            dataTestid={`set-up-biometrics`}
          >
            {isMobileSmallScreen
              ? t('webauthn_intercept_page:enable')
              : t('webauthn_intercept_page:enable-biometrics', {
                  userBiometricsLabel,
                })}
          </Button>
        </div>
        <Button
          onClick={handleDontAskAgain}
          linkStyle
          customStyles={styles.dontAskButton}
          textColor={config.theme?.colors?.secondary?.light1}
        >
          {t('webauthn_intercept_page:dont-ask-again')}
        </Button>
      </div>
    </div>
  );
};

export default WebauthnInterceptPage;
