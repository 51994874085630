enum PasswordLevel {
  SHORT = 'short',
  ERROR = 'error',
  COMPROMISED = 'compromised',
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
}

export default PasswordLevel;
