import { useTranslation } from 'react-i18next';
import { SvgIcon } from '../../../..';

import styles from '../AuthenticatorApp.module.css';

const Step1 = () => {
  const { t } = useTranslation('modal_authenticator_app');

  return (
    <>
      <p className={styles.title}>Get the app!</p>
      <p className={styles.desciption}>
        {t(
          'download-hanko-authenticator-from-the-google-play-store-or-apple-app-store-on-your-mobile-phone'
        )}
      </p>
      <div className={styles.appsContainer}>
        <a href="#appStore" aria-label="Hanko IOS app">
          <SvgIcon name="appStore" style={{ height: '55px' }} />
        </a>
        <a href="#playStore" aria-label="Hanko android app">
          <SvgIcon name="playStore" style={{ height: '45px' }} />
        </a>
      </div>
    </>
  );
};

export default Step1;
