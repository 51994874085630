import { useTranslation } from 'react-i18next';
import Modal from '../../Modal';
import { Components } from '../../../../../shared/data/types';
import styles from './DeleteRecord.module.css';
import { useKratosFlow } from '../../../../../shared/utils/hooks';
import {
  BaseError,
  SessionExpiredError,
} from '../../../../../shared/data/errors/error';
import { useHistory } from 'react-router';
import { useConfig } from '../../../context/Config';

const DeleteRecord = ({
  data,
  onCancel,
  flow,
  setFlow,
}: Components.Modal.DeleteRecordProps) => {
  const { config } = useConfig();
  const { t } = useTranslation(['modal_delete_record', 'common']);
  const history = useHistory();
  const {
    webauthnCredentialSettingsFlow,
    initializeSettingsFlow,
    normalizeKratosFlow,
  } = useKratosFlow();

  const handleCredentialDeletion = () => {
    webauthnCredentialSettingsFlow({
      settingsFlow: flow,
      credential_id: data.id,
      credential_action: 'delete',
    })
      .then(() => {
        initializeSettingsFlow().then((flow) =>
          setFlow(normalizeKratosFlow(flow))
        );
        onCancel();
      })
      .catch((error: BaseError) => {
        if (error instanceof SessionExpiredError) {
          history.push('/login');
        }
        console.error(error);
      });
  };

  return (
    <div>
      <p
        className={styles.title}
        style={{ color: config.theme.colors.brand.text }}
      >
        {t('modal_delete_record:delete-recordid', {
          credentialName: data.name,
        })}
      </p>
      <p
        className={styles.description}
        style={{ color: config.theme.colors.brand.text }}
      >
        {t(
          'modal_delete_record:do-you-really-want-to-delete-recordid-as-an-authentication-device-for-your-account',
          {
            credentialName: data.name,
          }
        )}
      </p>
      <Modal.ButtonWrapper
        onCancel={onCancel}
        onClick={() => {
          handleCredentialDeletion();
        }}
        label={t('common:delete')}
        dataTestid={`delete-record-${data.id}`}
      />
    </div>
  );
};

export default DeleteRecord;
