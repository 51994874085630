import { useTranslation } from 'react-i18next';
import { Form, PasswordBox } from '../../..';
import { Components } from '../../../../../shared/data/types';
import Modal from '../../Modal';
import styles from './EditPassword.module.css';
import {
  useKratosFlow,
  usePasswordSecurityText,
} from '../../../../../shared/utils/hooks';
import { useState } from 'react';
import { AlertBox } from '../../../';
import {
  BaseError,
  SessionExpiredError,
} from '../../../../../shared/data/errors/error';
import { useHistory } from 'react-router';
import { useConfig } from '../../../context/Config';

const EditPassword = ({
  onCancel,
  flow,
}: Components.Modal.EditPasswordProps) => {
  const { config } = useConfig();
  const { t } = useTranslation(['modal_edit_password', 'common']);
  const { passwordSettingsFlow } = useKratosFlow();
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [currentSecurityLevel, setCurrentSecurityLevel] =
    useState<any>(undefined);
  const [currentBreaches, setCurrentBreaches] = useState<any>(undefined);
  const { smallTip } = usePasswordSecurityText({
    securityLevel: currentSecurityLevel,
    totalBreaches: currentBreaches,
  });
  const history = useHistory();

  function handleScoreUpdate({
    securityLevel,
    totalBreaches,
  }: {
    securityLevel: any;
    totalBreaches: any;
  }) {
    setCurrentSecurityLevel(securityLevel);
    setCurrentBreaches(totalBreaches);
  }

  const handleSubmitPassword = () => {
    setIsLoading(false);
    setHasError(false);
    passwordSettingsFlow({ settingsFlow: flow, password })
      .then(() => {
        setHasError(false);
        setIsLoading(false);
        return onCancel();
      })
      .catch((error: BaseError) => {
        if (error instanceof SessionExpiredError) {
          history.push('/login');
        } else {
          setHasError(true);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <p
        className={styles.title}
        style={{ color: config.theme.colors.brand.text }}
      >
        {t('modal_edit_password:set-new-password')}
      </p>
      {hasError ? (
        <AlertBox type="error">{t('common:something-went-wrong')}</AlertBox>
      ) : null}
      <Form onSubmit={handleSubmitPassword}>
        <PasswordBox
          name="set-new-password"
          passwordStength
          showPassword
          hasFocus
          validatePattern
          onChange={(e) => setPassword(e.target.value)}
          scoreUpdated={({ securityLevel, totalBreaches }) =>
            handleScoreUpdate({ securityLevel, totalBreaches })
          }
        />
        <div className={styles.smallTipWrapper}>
          <p
            className={styles.smallTip}
            style={{ color: config.theme.colors.brand.text }}
          >
            {smallTip}
          </p>
          <Modal.ButtonWrapper
            onCancel={onCancel}
            onClick={() => {}}
            isLoading={isLoading}
            label={t('common:submit')}
            dataTestid={`edit-password-modal`}
          />
        </div>
      </Form>
    </>
  );
};

export default EditPassword;
