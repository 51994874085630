import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button, Modal, SvgIcon } from '../../..';
import { Components } from '../../../../../shared/data/types';
import Step1 from './step1/Step1';
import Step2 from './step2/Step2';
import Step3 from './step3/Step3';
import Multistep from '../../multistep/Multistep';
import styles from './AuthenticatorApp.module.css';
import { useConfig } from '../../../context/Config';

const AuthenticatorApp = ({
  onCancel,
  totalSteps,
}: Components.Modal.AuthenticatorAppProps) => {
  const { t } = useTranslation(['modal_authenticator_app', 'common']);
  const { config } = useConfig();
  const [activeStep, setActiveStep] = useState<number>(1);

  function handleNextStep() {
    if (activeStep < totalSteps) {
      setActiveStep(activeStep + 1);
    }
  }

  function handleGoBack() {
    if (activeStep >= 1) {
      setActiveStep(activeStep - 1);
    }
  }

  function handleSaveAuthName(name: string) {
    // @TODO: send data to the DB
    console.log(name);
    onCancel();
  }

  function renderStep(step: number) {
    switch (step) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 saveAuthName={handleSaveAuthName} />;

      default:
        return <Step1 />;
    }
  }

  return (
    <>
      {totalSteps ? (
        <Multistep totalSteps={totalSteps} activeStep={activeStep} />
      ) : null}
      <div className={styles.wrapper}>
        {renderStep(activeStep)}
        {activeStep === 1 ? (
          <Modal.ButtonWrapper
            onCancel={onCancel}
            onClick={handleNextStep}
            label={t('common:next')}
            dataTestid="modal-authenticator-app-next"
          />
        ) : activeStep !== 3 ? (
          <Button
            onClick={handleGoBack}
            linkStyle
            customStyles={styles.backButton}
            dataTestid="modal-authenticator-app-back"
          >
            <SvgIcon
              name="arrowLeft"
              fillColor={config.theme.colors.secondary.light2}
            />
            {t('common:back')}
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default AuthenticatorApp;
