import styles from './MainWrapper.module.css';

type Props = {
  children: React.ReactNode | React.ReactNodeArray;
};

const MainWrapper = ({ children }: Props) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export default MainWrapper;
