import cx from 'classnames';
import styles from './Title.module.css';
import { useConfig } from '../context/Config';

export type TitleProps = {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children: string;
  customStyles?: string;
  textColor?: string;
};

const Title = ({ type, children, customStyles, textColor }: TitleProps) => {
  const { config } = useConfig();
  const HeadingLevel = type as keyof JSX.IntrinsicElements;

  return (
    <HeadingLevel
      className={cx(styles.title, customStyles)}
      style={{ color: textColor || config.theme.colors.brand.text }}
    >
      {children}
    </HeadingLevel>
  );
};

Title.defaultProps = {
  type: 'h1',
};

export default Title;
