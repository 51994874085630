import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Components } from '../../data/types';
import { useKratosFlow } from './useKratosFlow';
import PasswordLevel from '../../../shared/data/enums/components/password-box';
import { useLocation } from 'react-router';

/**
 * Changes the visibility of an HTML element when clicking outside of it
 * @param initialState
 */
const useOutOfBoundsClick = (initialState: boolean) => {
  const [isVisible, setIsVisible] = useState(initialState);
  const ref = useRef<HTMLInputElement>(null);

  const handleClickOutside = (e: MouseEvent): void => {
    if (ref?.current && !ref?.current.contains(e.target as Node)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return { ref, isVisible, setIsVisible };
};

/**
 * Changes the visibility of a modal
 */

const useModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] =
    useState<Components.Modal.ModalProps['content']>();

  function toggleModal(): void {
    setIsModalVisible(!isModalVisible);
  }

  return { isModalVisible, toggleModal, modalContent, setModalContent };
};

/**
 * Displays the appropriate side text according to password strength score
 */
const usePasswordSecurityText = ({
  securityLevel,
  totalBreaches,
}: Components.PasswordBox.ScoreUpdate) => {
  const { t } = useTranslation('modal_edit_password');
  const DEFAULT_TIP = t(
    'modal_edit_password:your-password-should-be-at-least-8-characters-long-before-we-can-check-if-it-appears-in-any-known-breaches'
  );
  const [smallTip, setSmallTip] = useState<string>('bfoob');

  const updateTip = () => {
    switch (securityLevel) {
      case PasswordLevel.SHORT:
        return setSmallTip(DEFAULT_TIP);

      case PasswordLevel.ERROR:
        return setSmallTip(
          t(
            'an-error-occurred-and-we-could-not-validate-your-password-please-try-again-later'
          )
        );

      case PasswordLevel.COMPROMISED:
        return setSmallTip(
          t(
            'this-password-isnt-safe-to-use-and-appeared-in-usd-totalbreaches-known-data-breaches-you-can-still-use-it-but-you-probably-shouldnt',
            { totalBreaches }
          )
        );

      case PasswordLevel.ZERO:
      case PasswordLevel.ONE:
        return setSmallTip(
          t(
            'this-password-isnt-safe-to-use-you-can-still-use-it-but-you-probably-shouldnt'
          )
        );

      case PasswordLevel.TWO:
      case PasswordLevel.THREE:
      case PasswordLevel.FOUR:
        return setSmallTip(
          t(
            'this-password-is-safe-to-use-and-appeared-in-no-known-data-breaches'
          )
        );

      default:
        return setSmallTip(
          t(
            'your-password-should-be-at-least-8-characters-long-before-we-can-check-if-it-appears-in-any-known-breaches'
          )
        );
    }
  };

  useEffect(() => {
    updateTip();
  });

  return { smallTip };
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
};

export {
  useInterval,
  useOutOfBoundsClick,
  useModal,
  usePasswordSecurityText,
  useQuery,
  useKratosFlow,
};
