import { Link as RouterLink } from 'react-router-dom';
import cx from 'classnames';
import { IconsType } from '../../../shared/data/types';

import styles from './Link.module.css';
import { SvgIcon } from '..';
import { useConfig } from '../context/Config';

type Props = {
  buttonStyle?: boolean;
  children: string | React.ReactNodeArray;
  customStyles?: string;
  customWrapperStyles?: string;
  dataTestid?: string;
  icon?: IconsType['name'];
  isDisabled?: boolean;
  onClick?: (event: object) => void;
  onMouseEnter?: () => void;
  onMouseOut?: () => void;
  style?: object;
  to: any;
  tooltip?: string;
};

const Link = ({
  buttonStyle,
  children,
  customStyles = '',
  customWrapperStyles = '',
  dataTestid,
  icon,
  isDisabled,
  onClick,
  onMouseEnter,
  onMouseOut,
  style,
  to,
}: Props) => {
  const { config } = useConfig();
  const buttonStyles = buttonStyle
    ? {
        backgroundColor: config.theme.colors.secondary.light4,
        color: isDisabled
          ? config.theme.colors.secondary.light3
          : config.theme.colors.brand.text,
        borderRadius: `${config.theme?.components?.button?.secondary?.radius}px`,
        transition: 'all 0.08s',
      }
    : null;

  return (
    <RouterLink
      to={isDisabled ? '#' : to}
      onMouseEnter={onMouseEnter}
      onMouseOut={onMouseOut}
      onFocus={onMouseEnter}
      onBlur={onMouseOut}
      className={cx(styles.wrapper, customWrapperStyles, {
        [styles.disabled]: isDisabled,
      })}
    >
      <span
        className={cx(customStyles, {
          [styles.buttonStyle]: buttonStyle,
          [styles.disabled]: isDisabled,
        })}
        onClick={(e) => onClick && onClick(e)}
        style={{ ...style, ...buttonStyles }}
        data-testid={dataTestid}
      >
        {!!icon ? (
          <span className={styles.icon}>
            <SvgIcon
              name={icon}
              fillColor={
                isDisabled
                  ? config.theme.colors.secondary.light3
                  : config.theme.colors.brand.text
              }
            />
          </span>
        ) : null}
        {children}
        {!!icon ? (
          <span className={styles.arrow}>
            <SvgIcon
              name="arrowRight"
              fillColor={
                isDisabled
                  ? config.theme.colors.secondary.light3
                  : config.theme.colors.brand.text
              }
            />
          </span>
        ) : null}
      </span>
    </RouterLink>
  );
};

export default Link;
