import { useHistory } from 'react-router';
import { useConfig } from '../../../components/shared/context/Config';
import { useQuery } from './index';
import { OAuth2Client } from '../../data/types/oidc';
import { useOidcContext } from '../../../components/shared/context/OidcContext';
import { clientMatchesReturnTo } from '../oidc';

const DEFAULT_SUCCESS_REDIRECT = '/profile';

const useAfterLoginRedirect = () => {
  const { config } = useConfig();
  const query = useQuery();
  const history = useHistory();
  const oidcClients = useOidcContext();

  const appendQueryParamsTo = (url: string) => {
    const u = new URL(url, document.baseURI);
    query.forEach((value, param) => {
      u.searchParams.set(param, value);
    });
    return `${u.pathname.toString()}?${u.searchParams.toString()}`;
  };

  const makeLoginReturnToPath = (returnTo: string) => {
    if (query.get('return_to')) {
      return `/login?return_to=${encodeURIComponent(
        `${returnTo}?return_to=${query.get('return_to')}`
      )}`;
    } else {
      return `/login?return_to=${encodeURIComponent(returnTo)}`;
    }
  };

  const getReturnToUrlFromRedirectUrl = (
    redirectUrl?: string | null
  ): string | null => {
    if (redirectUrl && redirectUrl.length > 0) {
      const urlParams = new URL(redirectUrl);
      return urlParams.searchParams.get('return_to') || '';
    }
    return null;
  };

  const redirectTo = (returnToUrl?: string | null) => {
    if (returnToUrl && returnToUrl !== '') {
      const canRedirect = returnToUrl.startsWith('/')
        ? true
        : oidcClients.filter((client: OAuth2Client) => {
            return clientMatchesReturnTo(returnToUrl, client);
          }).length > 0;
      if (canRedirect) {
        window.location.replace(returnToUrl);
      } else {
        history.push('/error');
      }
    } else if (config.appSettings.redirectUrl !== '') {
      window.location.replace(config.appSettings.redirectUrl);
    } else {
      // use react router
      history.push(DEFAULT_SUCCESS_REDIRECT);
    }
  };

  const redirectToIntercept = (returnTo?: string | null) => {
    const url = new URL('/webauthn-setup', document.baseURI);
    if (returnTo) {
      url.searchParams.set('return_to', returnTo);
    }
    history.push(`${url.pathname.toString()}?${url.searchParams.toString()}`);
  };

  return {
    redirectTo,
    redirectToIntercept,
    getReturnToUrlFromRedirectUrl,
    appendQueryParamsTo,
    makeLoginReturnToPath,
  };
};

export { useAfterLoginRedirect };
