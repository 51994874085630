import { useState, useContext, useEffect } from 'react';
import { Tooltip } from '../../../components/shared';
import { Login } from '../../../shared/data/types';
import styles from './Platform.module.css';
import { useKratosFlow } from '../../../shared/utils/hooks';
import { useTranslation } from 'react-i18next';
import SelectButton from '../select-button/SelectButton';
import { OverlayContext } from '../../../components/shared/context';
import { useHistory } from 'react-router';
import {
  getPlatformAuthenticatorAlias,
  isUserVerifyingPlatformAuthenticatorAvailable,
} from '@teamhanko/hanko-webauthn';

const Platform = ({
  loginFlow,
  successRedirect,
  getUserEmail,
}: Login.LoginStepsProps) => {
  const { t } = useTranslation(['sign_in_page', 'common']);
  const { webauthnLoginFlow } = useKratosFlow();
  const { setIsOverlayVisible } = useContext(OverlayContext);
  const [tooltipVisibility, setTooltipVisibility] = useState(false);
  const [isBiometricsSupported, setIsBiometricsSupported] =
    useState<boolean>(false);
  const history = useHistory();

  function translateLabel(label: Login.LoginListProps['platformLabel']) {
    switch (label) {
      case 'Touch ID/Face ID':
        return t('sign_in_page:touch-id-face-id');
      case 'Windows Hello':
        return t('sign_in_page:windows-hello-0');
      case 'Android Biometrics':
        return t('sign_in_page:android-biometrics');
      case 'Platform Authenticator':
        return t('sign_in_page:platform-authenticator-0');

      default:
        return t('sign_in_page:platform-authenticator-0');
    }
  }

  function handleOnClick() {
    setIsOverlayVisible(true);
    webauthnLoginFlow({
      attachment: 'platform',
      loginFlow,
      userEmail: getUserEmail(),
    })
      .then(() => {
        successRedirect(false);
      })
      .catch((error) => {
        console.error(error.message);
        return history.replace('/login/error');
      })
      .finally(() => setIsOverlayVisible(false));
  }

  useEffect(() => {
    isUserVerifyingPlatformAuthenticatorAvailable().then((available) =>
      setIsBiometricsSupported(available)
    );
  }, []);

  return (
    <Tooltip
      isVisible={tooltipVisibility && !isBiometricsSupported}
      customStyles={styles.tooltip}
      tooltipText={t(
        'common:your-device-does-not-support-this-authentication-method'
      )}
    >
      <SelectButton
        onClick={handleOnClick}
        isDisabled={!isBiometricsSupported}
        onMouseEnter={() => setTooltipVisibility(true)}
        onMouseOut={() => setTooltipVisibility(false)}
        icon={'fingerprint'}
        customStyles={styles.fluidButton}
        buttonStyle
      >
        {translateLabel(getPlatformAuthenticatorAlias(navigator.userAgent))}
      </SelectButton>
    </Tooltip>
  );
};

export default Platform;
