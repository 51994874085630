import cx from 'classnames';
import { IconsType } from '../../../shared/data/types';
import styles from './SelectButton.module.css';
import { LoadingWheel, SvgIcon } from '../../../components/shared';
import { useConfig } from '../../../components/shared/context/Config';

type Props = {
  buttonStyle?: boolean;
  children: string | React.ReactNodeArray;
  customStyles?: string;
  dataTestid?: string;
  icon?: IconsType['name'];
  isDisabled?: boolean;
  onClick?: (event: object) => void;
  onMouseEnter?: () => void;
  onMouseOut?: () => void;
  style?: object;
  tooltip?: string;
  loading?: boolean;
};

const SelectButton = ({
  buttonStyle,
  children,
  customStyles = '',
  dataTestid,
  icon,
  isDisabled,
  onClick,
  onMouseEnter,
  onMouseOut,
  style,
  loading,
}: Props) => {
  const { config } = useConfig();
  const buttonStyles = buttonStyle
    ? {
        backgroundColor: config.theme?.components?.button?.secondary?.color,
        color: isDisabled
          ? config.theme.colors.secondary.light3
          : config.theme?.components?.button?.secondary?.text,
        borderRadius: `${config.theme?.components?.button?.secondary?.radius}px`,
        transition: 'all 0.08s',
      }
    : null;

  return (
    <button
      className={cx(customStyles, {
        [styles.buttonStyle]: buttonStyle,
        [styles.disabled]: isDisabled,
      })}
      onClick={(e) => onClick && !isDisabled && onClick(e)}
      style={{ ...style, ...buttonStyles }}
      data-testid={dataTestid}
      onMouseEnter={onMouseEnter}
      onMouseOut={onMouseOut}
      onFocus={onMouseEnter}
      onBlur={onMouseOut}
    >
      {!!icon ? (
        <span className={styles.iconWrapper}>
          <SvgIcon
            style={{ width: '25px' }}
            name={icon}
            fillColor={
              isDisabled
                ? config.theme.colors.secondary.light3
                : config.theme.colors.secondary.default
            }
          />
          <hr
            className={styles.divider}
            style={{
              backgroundColor: isDisabled
                ? config.theme.colors.secondary.light3
                : config.theme.colors.secondary.default,
            }}
          />
        </span>
      ) : null}
      {children}
      {!!icon ? (
        <span className={styles.loading}>
          {loading ? <LoadingWheel size="small" /> : null}
        </span>
      ) : null}
    </button>
  );
};

export default SelectButton;
