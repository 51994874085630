import ProgressCircle from './/ProgressCircle';
import { useEffect, useState } from 'react';
import styles from './RateLimitIndicator.module.css';
import { Link } from '../../../components/shared';
import { useConfig } from '../context/Config';
import { useTranslation } from 'react-i18next';

type Props = {
  sendPasslinkCallback: () => Promise<void>;
};

const RateLimitIndicator = ({ sendPasslinkCallback }: Props) => {
  const { config } = useConfig();
  const localStorageKey = 'last_passlink_sent_at';
  const { t } = useTranslation(['sign_in_page']);
  const [isNextPasslinkAvailable, setIsNextPasslinkAvailable] =
    useState<boolean>(false);
  const [lastPasslinkSentAt, setLastPasslinkSentAt] = useState<Date | null>(
    null
  );

  const sendEmail = () => {
    setIsNextPasslinkAvailable(false);
    sendPasslinkCallback()
      .then(() => initializeTimer())
      .catch(() => timerCallback());
  };

  const initializeTimer = () => {
    const now = new Date();
    setLastPasslinkSentAtToLocalStorage(now);
    setLastPasslinkSentAt(now);
  };

  const setLastPasslinkSentAtToLocalStorage = (date: Date) => {
    localStorage.setItem(localStorageKey, date.toISOString());
  };

  const getLastPasslinkSentAtFromLocalStorage = (): Date | null => {
    const storedValue = localStorage.getItem(localStorageKey);
    if (!storedValue) return null;
    const d = Date.parse(storedValue);
    return !isNaN(d) ? new Date(d) : null;
  };

  const timerCallback = () => {
    setIsNextPasslinkAvailable(true);
    setLastPasslinkSentAt(null);
    localStorage.removeItem(localStorageKey);
  };

  useEffect(() => {
    const now = new Date();
    const lastSent = getLastPasslinkSentAtFromLocalStorage();
    const rateLimitDuration = config.appSettings.passlinkRateLimitDuration;

    if (!lastSent || now.getTime() > lastSent.getTime() + rateLimitDuration) {
      setIsNextPasslinkAvailable(false);
      sendPasslinkCallback().then(() => initializeTimer());
    } else {
      setLastPasslinkSentAt(lastSent);
    }
  }, []);

  return !isNextPasslinkAvailable ? (
    <>
      <span className={styles.circleWrapper}>
        <ProgressCircle
          timerStartAt={lastPasslinkSentAt}
          timerCallback={timerCallback}
        />
      </span>{' '}
      <span style={{ color: config.theme.colors.secondary.light2 }}>
        {t('sign_in_page:email-sent')}
      </span>
    </>
  ) : (
    <Link
      style={{ color: config.theme.colors.secondary.light2 }}
      customStyles={styles.link}
      onClick={sendEmail}
      to={null}
    >
      {t('sign_in_page:send-email-again')}
    </Link>
  );
};

export default RateLimitIndicator;
