import { Suspense, useState } from 'react';
import {
  BrowserRouter as Router,
  BrowserRouter,
  Redirect,
  Route,
  RouteProps,
  StaticRouter,
  Switch,
} from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';

import MainApp from './components/main-app/MainApp';
import { LoadingWheel, Overlay } from './components/shared';
import {
  OverlayContext,
  RouteContext,
  UserContext,
} from './components/shared/context';
import {
  AccountPage,
  LoginPage,
  OnboardingPage,
  ProfilePage,
  RegisterPage,
  WebauthnInterceptPage,
} from './pages';
import SessionWrapper from './components/session-wrapper/SessionWrapper';
import { CookiesProvider } from 'react-cookie';
import RecoveryPage from './pages/recovery-page/RecoveryPage';
import RecoveryError from './pages/recovery-page/RecoveryError';
import VerificationError from './pages/verification-page/VerificationError';
import ConfigContextProvider from './components/shared/context/Config';
import Screen from './components/shared/screen/Screen';
import DocumentHead from './components/shared/document-head/DocumentHead';
import ErrorPage from './pages/error-page/ErrorPage';
import { OidcContextProvider } from './components/shared/context/OidcContext';

const AppRoutes = () => (
  <Switch>
    <Route path="/register">
      <SessionWrapper shouldRedirectValidUser>
        <RegisterPage />
      </SessionWrapper>
    </Route>
    <Route path="/login">
      <LoginPage />
    </Route>
    <Route path="/profile" exact>
      <SessionWrapper>
        <ProfilePage />
      </SessionWrapper>
    </Route>
    {/* <Route path="/security" exact>
      <SessionWrapper>
        <SecurityPage />
      </SessionWrapper>
    </Route>
    {/* <Route path="/history" exact>
      <SessionWrapper>
        <SignInHistoryPage />
      </SessionWrapper>
    </Route> */}
    <Route path="/account" exact>
      <SessionWrapper>
        <AccountPage />
      </SessionWrapper>
    </Route>
    <Route path="/onboarding" exact>
      <SessionWrapper>
        <OnboardingPage />
      </SessionWrapper>
    </Route>
    <Route path="/recovery" exact>
      <SessionWrapper shouldRedirectValidUser>
        <RecoveryPage />
      </SessionWrapper>
    </Route>
    <Route path="/recovery/error" exact>
      <RecoveryError />
    </Route>
    <Route path="/verification/error" exact>
      <VerificationError />
    </Route>
    <Route path="/webauthn-setup" exact>
      <SessionWrapper>
        <WebauthnInterceptPage />
      </SessionWrapper>
    </Route>
    <Route path="/error">
      <ErrorPage />
    </Route>
    <Route>
      <Redirect to="/login" />
    </Route>
  </Switch>
);

const App = (props: RouteProps) => {
  const [isRouteRegister, setIsRouteRegister] = useState<boolean>(false);
  const [shouldShowSidebarBackButton, setShouldShowSidebarBackButton] =
    useState<boolean>(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const [userName, setUserName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userDegree, setUserDegree] = useState('');

  return (
    <Router>
      <RouteContext.Provider
        value={{
          isRouteRegister,
          setIsRouteRegister,
          shouldShowSidebarBackButton,
          setShouldShowSidebarBackButton,
        }}
      >
        <UserContext.Provider
          value={{
            userEmail,
            setUserEmail,
            userName,
            setUserName,
            userLastName,
            setUserLastName,
            userDegree,
            setUserDegree,
          }}
        >
          <ConfigContextProvider>
            <OidcContextProvider>
              <OverlayContext.Provider
                value={{
                  isOverlayVisible,
                  setIsOverlayVisible,
                }}
              >
                <CookiesProvider>
                  <Screen>
                    <DocumentHead />
                    <Overlay isVisible={isOverlayVisible} />
                    <Suspense fallback={<LoadingWheel />}>
                      <Sidebar
                        showBackButton={shouldShowSidebarBackButton}
                        isRouteRegister={isRouteRegister}
                      />
                    </Suspense>
                    <MainApp>
                      <Suspense fallback={<LoadingWheel />}>
                        {props.location ? (
                          <StaticRouter location={props.location} context={{}}>
                            <AppRoutes />
                          </StaticRouter>
                        ) : (
                          <BrowserRouter>
                            <AppRoutes />
                          </BrowserRouter>
                        )}
                      </Suspense>
                    </MainApp>
                  </Screen>
                </CookiesProvider>
              </OverlayContext.Provider>
            </OidcContextProvider>
          </ConfigContextProvider>
        </UserContext.Provider>
      </RouteContext.Provider>
    </Router>
  );
};

export default App;
