import cx from 'classnames';
import { TooltipProps } from '../../../shared/data/types/components';
import styles from './Tooltip.module.css';
import { useConfig } from '../context/Config';

const Tooltip = ({
  children,
  customStyles,
  isVisible,
  tooltipText,
}: TooltipProps) => {
  const { config } = useConfig();

  return (
    <div className={styles.wrapper}>
      {isVisible ? (
        <div
          className={cx(styles.tooltipWrapper, customStyles)}
          style={{ backgroundColor: config.theme?.colors?.secondary?.light2 }}
        >
          {tooltipText}
        </div>
      ) : null}
      {children}
    </div>
  );
};

export default Tooltip;
