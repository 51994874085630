import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { BreadcrumbsProps } from '../../../shared/data/types/components';
import { SvgIcon } from '..';

import styles from './Breadcrumbs.module.css';
import { useConfig } from '../context/Config';

const Breadcrumbs = ({
  children,
  currentPage,
  customStyles,
}: BreadcrumbsProps) => {
  const { config } = useConfig();
  const { t } = useTranslation(['account_page']);
  const pageName = (route: string) => {
    switch (route) {
      case 'account':
        return t('account-settings');

      default:
        return t('account-settings');
    }
  };

  return (
    <nav className={cx(styles.wrapper, customStyles)}>
      <ul
        className={styles.list}
        style={{ color: config.theme.colors.brand.text }}
      >
        <li className={styles.link}>{children}</li>
        {currentPage ? (
          <>
            <li className={styles.arrowRight}>
              <SvgIcon
                name="arrowRight"
                fillColor={config.theme.colors.brand.text}
              />
            </li>
            <li className={styles.current}>{pageName(currentPage)}</li>
          </>
        ) : null}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
