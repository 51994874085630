import cx from 'classnames';
import { ReactComponent as DefaultLogo } from '../../assets/logo/logo_white.svg';

import styles from './Logo.module.css';
import { useConfig } from '../shared/context/Config';

type Props = {
  customLogo?: string;
  customStyles?: string;
};

const Logo = ({ customLogo, customStyles }: Props) => {
  const { config } = useConfig();
  return (
    <div className={cx(styles.logo, customStyles)}>
      {customLogo || config.theme?.brand?.logo ? (
        <img
          src={customLogo || config.theme?.brand?.logo}
          alt="Logo"
          className={styles.image}
        />
      ) : (
        <DefaultLogo title="Logo" className={styles.image} />
      )}
    </div>
  );
};

export default Logo;
