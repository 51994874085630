import Logo from '../../logo/Logo';
import { SidebarProps } from '../../../shared/data/types/components';
import BurgerButton from '../../burger-button/BurgerButton';

import styles from './Sidebar.module.css';
import BackToAppButton from '../BackToAppButton';
import { useConfig } from '../../shared/context/Config';

const Sidebar = ({
  hasBackButton,
  isMenuVisible,
  isBurgerMenuVisible,
  onBurgerButtonClick,
  radius,
  foregroundColor,
}: SidebarProps) => {
  const { config } = useConfig();
  const handleBurgerButtonClick = (isButtonActive: boolean | undefined) => {
    onBurgerButtonClick && onBurgerButtonClick(!!isButtonActive);
  };

  return (
    <div
      className={styles.header}
      style={{
        backgroundColor: config.theme.colors.sidebar.background,
      }}
    >
      {!hasBackButton ? <Logo customStyles={styles.logoSmall} /> : null}
      {hasBackButton ? (
        <BackToAppButton
          foregroundColor={foregroundColor}
          radius={radius}
          isDesktop={false}
        />
      ) : null}
      {isBurgerMenuVisible ? (
        <div className={styles.burgerButtonWrapper}>
          <BurgerButton
            onClick={handleBurgerButtonClick}
            isMenuVisible={isMenuVisible}
          />
        </div>
      ) : null}
    </div>
  );
};

Sidebar.defaultProps = {
  isBurgerMenuVisible: false,
};

export default Sidebar;
