import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { Button, Form, Textbox, Title } from '../../components/shared';
import styles from './LoginPage.module.css';

import { EMAIL_PATTERN } from '../../shared/data/constants';
import { OverlayContext, RouteContext } from '../../components/shared/context';

import { useKratosFlow, useQuery } from '../../shared/utils/hooks';
import { BaseError } from '../../shared/data/errors/error';
import Passlink from './passlink/Passlink';
import Error from './error/Error';
import Password from './password/Password';
import { useAfterLoginRedirect } from '../../shared/utils/hooks/useAfterLoginRedirect';
import usePlatformAuthCookie from '../../shared/utils/hooks/usePlatformAuthCookie';
import { KRATOS_SESSION_WHOAMI } from '../../shared/utils/apiConstants';
import { Link } from 'react-router-dom';
import { isUserVerifyingPlatformAuthenticatorAvailable } from '@teamhanko/hanko-webauthn';
import { useConfig } from '../../components/shared/context/Config';
import BackButtonPlaceholder from '../../components/shared/back-button/BackButtonPlaceholder';

const LoginPage = () => {
  const { config } = useConfig();
  const { t } = useTranslation(['sign_in_page', 'common']);
  const query = useQuery();
  const {
    newFlow,
    setNewFlow,
    normalizeKratosFlow,
    webauthnLoginFlow,
    initializeLoginFlow,
  } = useKratosFlow();

  const history = useHistory();
  const { hasCredentialWithEmail } = usePlatformAuthCookie();
  const [userEmail, setUserEmail] = useState<string>(
    localStorage.getItem('lastUsedUsername') || ''
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
  const { setIsOverlayVisible } = useContext(OverlayContext);
  const { redirectTo, redirectToIntercept, appendQueryParamsTo } =
    useAfterLoginRedirect();
  const [platformAuthenticatorAvailable, setPlatformAuthenticatorAvailable] =
    useState(false);
  const { setIsRouteRegister, setShouldShowSidebarBackButton } =
    useContext(RouteContext);

  const handleOnSubmit = async () => {
    setIsLoading(true);
    // userGesture only passed on to first promise. So please no function calls before webauthnLoginFlow
    if (userEmail.length > 0 && userEmail.match(EMAIL_PATTERN)) {
      localStorage.setItem('lastEnteredUsername', userEmail);

      if (hasCredentialWithEmail(userEmail) && platformAuthenticatorAvailable) {
        setIsOverlayVisible(true);
        webauthnLoginFlow({
          attachment: 'platform',
          loginFlow: newFlow,
          userEmail,
        })
          .then(() => {
            successRedirect(false);
          })
          .catch((error: BaseError) => {
            setIsLoading(false);
            console.error(error.message);
            history.push(appendQueryParamsTo('/login/error'));
          })
          .finally(() => {
            setIsOverlayVisible(false);
          });
      } else {
        setIsLoading(false);
        history.push(
          appendQueryParamsTo(
            config.appSettings.login.loginOptions.includes('password')
              ? '/login/password'
              : '/login/passlink'
          )
        );
      }
    }

    return false;
  };

  const successRedirect = (showIntercept: boolean): void => {
    const returnTo = query.get('return_to');
    localStorage.setItem('lastUsedUsername', getUserEmail());
    showIntercept ? redirectToIntercept(returnTo) : redirectTo(returnTo);
  };

  const getUserEmail = (): string => {
    return localStorage.getItem('lastEnteredUsername') || userEmail;
  };

  useEffect(() => {
    setIsRouteRegister(false);
    setShouldShowSidebarBackButton(false);
  }, []);

  useEffect(() => {
    isUserVerifyingPlatformAuthenticatorAvailable().then((res) => {
      setPlatformAuthenticatorAvailable(res);
    });
  }, []);

  useEffect(() => {
    initializeLoginFlow(query.get('return_to'))
      .then((newFlow) => setNewFlow(normalizeKratosFlow(newFlow)))
      .catch((error: BaseError) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_KRATOS_PUBLIC_URL}/${KRATOS_SESSION_WHOAMI}`,
      {
        method: 'GET',
        credentials: 'include',
      }
    )
      .then((response) => {
        if (response.ok) {
          // user already logged in
          redirectTo(query.get('return_to'));
        } else {
          // display login screen
          setIsPageLoaded(true);
        }
      })
      .catch(() => setIsPageLoaded(true));
  }, []);

  return isPageLoaded ? (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Switch>
          <Route path="/login" exact>
            <BackButtonPlaceholder />
            <Title customStyles={styles.title}>{`${t('sign-in-to')} ${
              config.theme?.brand?.name
            }`}</Title>
            <Form onSubmit={handleOnSubmit}>
              <Textbox
                type="email"
                required
                name="login-email"
                pattern={EMAIL_PATTERN}
                hasFocus
                defaultValue={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                placeholder={t('common:email')}
                hasButton
              />
              <Button
                onClick={() => {}}
                type="submit"
                primary
                loading={isLoading}
                customStyles={styles.signInButton}
              >
                {t('sign-in')}
              </Button>
            </Form>
            {config.appSettings.signUp.signUpEnabled ||
            config.appSettings.signUp.signUpCustomUrl ? (
              <div
                className={styles.signUpCta}
                style={{ color: config.theme.colors.brand.text }}
              >
                {t('dont-have-an-account')}
                <span className={styles.ctaLink}>
                  {config.appSettings.signUp.signUpCustomUrl ? (
                    <a href={config.appSettings.signUp.signUpCustomUrl}>
                      {t('create-account')}
                    </a>
                  ) : (
                    <Link to={appendQueryParamsTo('/register')}>
                      {t('create-account')}
                    </Link>
                  )}
                </span>
              </div>
            ) : null}
          </Route>
          <Route path="/login/passlink" exact>
            <>
              <Passlink userEmail={userEmail} loginFlow={newFlow} />
            </>
          </Route>
          <Route path="/login/password">
            <>
              <Password
                successRedirect={successRedirect}
                getUserEmail={getUserEmail}
                loginFlow={newFlow}
              />
            </>
          </Route>
          <Route path="/login/error">
            <>
              <Error
                successRedirect={successRedirect}
                getUserEmail={getUserEmail}
                loginFlow={newFlow}
              />
            </>
          </Route>
          <Redirect to="/login" />
        </Switch>
      </div>
    </div>
  ) : null;
};

export default LoginPage;
