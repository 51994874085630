import { useState } from 'react';
import {
  create as createCredential,
  get as getCredential,
} from '@teamhanko/hanko-webauthn';
import {
  LoginFlow,
  RecoveryFlow,
  RegistrationFlow,
  SettingsFlow,
  SettingsViaApiResponse,
  UiNode,
  UiText,
} from '@ory/kratos-client';
import { NormalizedLoginFlow } from '../../data/types/kratosFlows';
import {
  KRATOS_LOGIN,
  KRATOS_RECOVERY,
  KRATOS_REGISTRATION,
  KRATOS_SETTINGS,
} from '../apiConstants';
import {
  DuplicateCredentialsError,
  GeneralError,
  InvalidCredentialsError,
  LinkExpiredError,
  SessionExpiredError,
  TooManyRequestsError,
  PasswordTooSimilarToIdentifierError,
} from '../../data/errors/error';

type PasswordLoginFlowRequest = {
  loginFlow: NormalizedLoginFlow;
  userEmail: string;
  password: string;
};

export type WebauthnLoginFlowRequest = {
  attachment: 'platform' | 'cross-platform';
  loginFlow: NormalizedLoginFlow;
  userEmail: string;
};

type PasslinkLoginFlowRequest = {
  loginFlow: NormalizedLoginFlow;
  userEmail?: string;
  linkID?: string;
  template?: string | null;
  template_locale?: string | null;
};

type PasslinkRecoveryFlowRequest = {
  recoveryFlow?: NormalizedLoginFlow;
  userEmail?: string;
  linkID?: string;
};

export type WebauthnSettingsFlowRequest = {
  attachment: 'platform' | 'cross-platform';
  settingsFlow: NormalizedLoginFlow;
};

export type WebauthnCredentialSettingsFlowRequest = {
  settingsFlow: NormalizedLoginFlow;
  credential_id: string;
  credential_name?: string;
  credential_action: 'update' | 'delete';
};

type ProfileSettingsFlowRequest = {
  settingsFlow: NormalizedLoginFlow;
  userEmail: string;
  firstName: string;
  lastName: string;
  title: string;
};

type PasswordSettingsFlowRequest = {
  settingsFlow: NormalizedLoginFlow;
  password: string;
};

type RegistrationFlowRequest = {
  registrationFlow: NormalizedLoginFlow;
  userEmail: string;
  firstName: string;
  lastName: string;
};

const useKratosFlow = () => {
  const [newFlow, setNewFlow] = useState<NormalizedLoginFlow>(
    {} as NormalizedLoginFlow
  );
  const normalizeKratosFlow = (
    oldFlow: LoginFlow | SettingsFlow | RegistrationFlow | RecoveryFlow
  ): NormalizedLoginFlow => {
    const nodes: UiNode[] = oldFlow?.ui?.nodes;
    const flowData = nodes.reduce((acc: any, node: any) => {
      const { attributes } = node;

      if (acc?.group?.[node.group]) {
        return {
          group: {
            ...acc?.group,
            [node.group]: {
              ...acc.group[node.group],
              [attributes.name]: attributes.value || '',
            },
          },
        };
      }

      return {
        group: {
          ...acc?.group,
          [node.group]: {
            [attributes.name]: attributes.value || '',
          },
        },
      };
    }, {} as NormalizedLoginFlow);

    return {
      ...flowData,
      flow: oldFlow,
    };
  };

  const refreshLoginFlow = async (
    response: Response
  ): Promise<NormalizedLoginFlow> => {
    const responseUrl = new URL(response.url);
    const flowId = responseUrl.searchParams.get('id');
    if (!flowId) {
      throw new GeneralError();
    }
    const flow = await getLoginFlow(flowId);
    const normalized = normalizeKratosFlow(flow);
    await setNewFlow(normalized);
    return normalized;
  };

  const updateFlowRequest = async (flow: NormalizedLoginFlow, data: any) => {
    return await fetch(flow.flow?.ui.action, {
      method: flow.flow?.ui.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        csrf_token: flow.group.default.csrf_token,
        ...data,
      }),
    });
  };

  const initializeFlow = async (url: string, returnTo?: string | null) => {
    if (returnTo) {
      const u = new URL(url, document.baseURI);
      u.searchParams.set('return_to', returnTo);
      url = u.toString();
    }
    return await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
  };

  const initializeLoginFlow = async (
    returnTo?: string | null
  ): Promise<LoginFlow> => {
    const response = await initializeFlow(
      `${process.env.REACT_APP_KRATOS_PUBLIC_URL}/${KRATOS_LOGIN}`,
      returnTo
    );
    if (response.status === 200) {
      return (await response.json()) as Promise<LoginFlow>;
    } else {
      throw new GeneralError();
    }
  };

  const initializeSettingsFlow = async (
    returnTo?: string | null
  ): Promise<SettingsFlow> => {
    const response = await initializeFlow(
      `${process.env.REACT_APP_KRATOS_PUBLIC_URL}/${KRATOS_SETTINGS}`,
      returnTo
    );
    if (response.status === 200) {
      return (await response.json()) as Promise<SettingsFlow>;
    } else if (response.status === 401 || response.status === 403) {
      throw new SessionExpiredError();
    } else {
      throw new GeneralError();
    }
  };

  const initializeRecoveryFlow = async (
    returnTo?: string | null
  ): Promise<SettingsFlow> => {
    const response = await initializeFlow(
      `${process.env.REACT_APP_KRATOS_PUBLIC_URL}/${KRATOS_RECOVERY}`,
      returnTo
    );
    if (response.status === 200) {
      return (await response.json()) as Promise<SettingsFlow>;
    } else {
      throw new GeneralError();
    }
  };

  const initializeRegistrationFlow = async (
    returnTo?: string | null
  ): Promise<SettingsFlow> => {
    const response = await initializeFlow(
      `${process.env.REACT_APP_KRATOS_PUBLIC_URL}/${KRATOS_REGISTRATION}`,
      returnTo
    );
    if (response.status === 200) {
      return (await response.json()) as Promise<SettingsFlow>;
    } else {
      throw new GeneralError();
    }
  };

  const getSettingsFlow = async (flowID: string): Promise<SettingsFlow> => {
    const response = await initializeFlow(
      `${process.env.REACT_APP_KRATOS_PUBLIC_URL}/self-service/settings/flows?id=${flowID}` // settings/browser?
    );
    if (response.status === 200) {
      return (await response.json()) as Promise<SettingsFlow>;
    } else if (response.status === 410) {
      throw new LinkExpiredError();
    } else if (response.status === 401 || response.status === 403) {
      throw new SessionExpiredError();
    } else {
      throw new GeneralError();
    }
  };

  const getLoginFlow = async (flowID: string): Promise<LoginFlow> => {
    const response = await initializeFlow(
      `${process.env.REACT_APP_KRATOS_PUBLIC_URL}/self-service/login/flows?id=${flowID}`
    );
    if (response.status === 200) {
      return (await response.json()) as Promise<LoginFlow>;
    } else {
      throw new GeneralError();
    }
  };

  const webauthnLoginFlow = async (request: WebauthnLoginFlowRequest) => {
    const challengeResponse = await updateFlowRequest(request.loginFlow, {
      method: 'hanko',
      attachment: request.attachment,
      user_identifier: request.userEmail,
    });

    if (challengeResponse.status === 400) {
      const flow = normalizeKratosFlow(
        (await challengeResponse.json()) as LoginFlow
      );
      if (flow.group.hanko?.challenge === '') {
        throw new GeneralError(); // challenge expected
      }
      let publicKey;
      try {
        publicKey = await getCredential(
          JSON.parse(flow?.group.hanko.challenge)
        );
      } catch (error) {
        throw new InvalidCredentialsError(error);
      }

      const publicKeyResponse = await updateFlowRequest(flow, {
        method: 'hanko',
        attachment: request.attachment,
        user_identifier: request.userEmail,
        public_key: JSON.stringify(publicKey),
      });

      if (publicKeyResponse.status === 200) {
        return; // ok
      } else if (publicKeyResponse.status === 401) {
        throw new InvalidCredentialsError();
      } else {
        throw new GeneralError();
      }
    } else if (
      challengeResponse.status === 200 &&
      challengeResponse.redirected
    ) {
      const loginFlow = await refreshLoginFlow(challengeResponse);
      await webauthnLoginFlow({
        ...request,
        loginFlow,
      });
    } else {
      throw new GeneralError();
    }
  };

  const getRecoveryFlow = async (flowId: string) => {
    return await fetch(
      `${process.env.REACT_APP_KRATOS_PUBLIC_URL}/self-service/recovery/flows?id=${flowId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
    );
  };

  const passlinkLoginFlow = async (request: PasslinkLoginFlowRequest) => {
    const response = await updateFlowRequest(request.loginFlow, {
      method: 'passlink',
      user_identifier: request.userEmail,
      link_id: request.linkID,
      template: request.template,

      // If the ui language has automatically been detected by i18next because no locale was set in the config (setting
      // the config overrides the detected locale), it may be that the detected locale does not contain a region, which
      // is required by the passlink api. Because all identity-tenants should currently have a fixed locale configured,
      // and no auto detection should happen, set template_locale to the config language. If it contains an invalid
      // value, the passlink api will fallback to en_GB.
      template_locale: request.template_locale,
    });

    if (response.status === 200) {
      if (response.redirected) {
        const loginFlow = await refreshLoginFlow(response);
        await passlinkLoginFlow({ ...request, loginFlow });
      } else {
        // login ok
        return;
      }
    } else if (response.status === 400) {
      const flow = (await response.json()) as LoginFlow;
      setNewFlow(normalizeKratosFlow(flow));
      mapKratosError(flow.ui.messages || []);
    } else {
      throw new GeneralError();
    }
  };

  const passLinkRecoveryFlow = async (request: PasslinkRecoveryFlowRequest) => {
    const response = await fetch(
      `${process.env.REACT_APP_KRATOS_PUBLIC_URL}/${KRATOS_RECOVERY}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          method: 'passlink',
          csrf_token: request.recoveryFlow?.group.default.csrf_token,
          email: request.userEmail,
          token: request.linkID,
          flow: request.recoveryFlow?.flow.id,
        }),
      }
    );

    if (response.status === 200) {
      return;
    } else if (response.status === 400) {
      const flow = (await response.json()) as RecoveryFlow;
      setNewFlow(normalizeKratosFlow(flow));
      mapKratosError(flow.ui.messages || []);
    } else {
      throw new GeneralError();
    }
  };

  const passwordLoginFlow = async (request: PasswordLoginFlowRequest) => {
    const response = await updateFlowRequest(request.loginFlow, {
      method: 'password',
      password_identifier: request.userEmail,
      password: request.password,
    });

    if (response.status === 200) {
      if (response.redirected) {
        const loginFlow = await refreshLoginFlow(response);
        await passwordLoginFlow({ ...request, loginFlow });
      } else {
        // login ok
        return;
      }
    } else if (response.status === 400) {
      const flow = (await response.json()) as LoginFlow;
      setNewFlow(normalizeKratosFlow(flow));
      mapKratosError(flow.ui.messages || []);
      throw new GeneralError();
    } else if (response.status === 401 || response.status === 403) {
      throw new SessionExpiredError();
    } else {
      throw new GeneralError();
    }
  };

  const profileSettingsFlow = async (request: ProfileSettingsFlowRequest) => {
    const response = await updateFlowRequest(request.settingsFlow, {
      method: 'profile',
      'traits.email': request.userEmail,
      'traits.name.first': request.firstName,
      'traits.name.last': request.lastName,
      'traits.name.academic-degree': request.title,
    });

    if (response.status === 200) {
      // ok
      return;
    } else if (response.status === 400) {
      const flow = (await response.json()) as LoginFlow;
      setNewFlow(normalizeKratosFlow(flow));
      mapKratosError(flow.ui.messages || []);
    } else if (response.status === 401 || response.status === 403) {
      throw new SessionExpiredError();
    } else {
      throw new GeneralError();
    }
  };

  const passwordSettingsFlow = async (request: PasswordSettingsFlowRequest) => {
    const response = await updateFlowRequest(request.settingsFlow, {
      method: 'password',
      password: request.password,
    });
    if (response.status === 200) {
      // password update ok
      return;
    } else if (response.status === 400) {
      const flow = (await response.json()) as SettingsFlow;
      setNewFlow(normalizeKratosFlow(flow));
      mapKratosError(flow.ui.messages || []);
      mapUiNodeError(flow.ui.nodes);
    } else if (response.status === 401 || response.status === 403) {
      throw new SessionExpiredError();
    } else {
      throw new GeneralError();
    }
  };

  const registrationFlow = async (request: RegistrationFlowRequest) => {
    const response = await updateFlowRequest(request.registrationFlow, {
      method: 'account',
      'traits.email': request.userEmail,
      'traits.name.first': request.firstName,
      'traits.name.last': request.lastName,
    });

    if (response.status === 200) {
      return;
    } else if (response.status === 400) {
      const flow = (await response.json()) as RegistrationFlow;
      setNewFlow(normalizeKratosFlow(flow));
      mapKratosError(flow.ui.messages || []);
    } else {
      throw new GeneralError();
    }
  };

  const webauthnSettingsFlow = async (request: WebauthnSettingsFlowRequest) => {
    const challengeResponse = await updateFlowRequest(request.settingsFlow, {
      method: 'hanko',
      attachment: request.attachment,
    });

    if (challengeResponse.status === 200) {
      throw new GeneralError(); // unexpected status code
    } else if (challengeResponse.status === 400) {
      const flow = normalizeKratosFlow(
        (await challengeResponse.json()) as SettingsFlow
      );

      if (flow.group.hanko?.challenge === '') {
        throw new GeneralError(); // challenge expected
      }

      const publicKey = await createCredential(
        JSON.parse(flow?.group.hanko.challenge)
      );

      const publicKeyResponse = await updateFlowRequest(flow, {
        method: 'hanko',
        public_key: JSON.stringify(publicKey),
        credential_action: 'create',
        attachment: request.attachment,
      });

      if (publicKeyResponse.status === 200) {
        setNewFlow(
          normalizeKratosFlow(
            ((await publicKeyResponse.json()) as SettingsViaApiResponse).flow
          )
        );
        return; // ok
      } else if (
        publicKeyResponse.status === 401 ||
        publicKeyResponse.status === 403
      ) {
        throw new SessionExpiredError();
      } else {
        throw new GeneralError();
      }
    } else if (
      challengeResponse.status === 401 ||
      challengeResponse.status === 403
    ) {
      throw new SessionExpiredError();
    } else {
      throw new GeneralError();
    }
  };

  const webauthnCredentialSettingsFlow = async (
    request: WebauthnCredentialSettingsFlowRequest
  ) => {
    const response = await updateFlowRequest(request.settingsFlow, {
      method: 'hanko',
      credential_id: request.credential_id,
      credential_name: request.credential_name,
      credential_action: request.credential_action,
    });
    if (response.status === 200) {
      // credential update ok
      return;
    } else if (response.status === 400) {
      const flow = (await response.json()) as SettingsFlow;
      setNewFlow(normalizeKratosFlow(flow));
      mapKratosError(flow.ui.messages || []);
    } else if (response.status === 401 || response.status === 403) {
      throw new SessionExpiredError();
    } else {
      throw new GeneralError();
    }
  };

  return {
    newFlow,
    setNewFlow,
    normalizeKratosFlow,
    initializeLoginFlow,
    initializeSettingsFlow,
    initializeRecoveryFlow,
    initializeRegistrationFlow,
    getSettingsFlow,
    getRecoveryFlow,
    webauthnLoginFlow,
    passwordLoginFlow,
    passlinkLoginFlow,
    passLinkRecoveryFlow,
    webauthnSettingsFlow,
    webauthnCredentialSettingsFlow,
    passwordSettingsFlow,
    profileSettingsFlow,
    registrationFlow,
  };
};

export { useKratosFlow };

enum KratosError {
  PasswordTooSimilarToIdentifier = 4000005,
  InvalidCredentials = 4000006,
  DuplicateCredentials = 4000007,
  InternalServer = 6000000,
  BadRequest,
  Conflict,
  NotFound,
  TooManyRequests,
  Unknown,
}

const mapKratosError = (messages: Array<UiText>) => {
  messages?.forEach((text) => {
    switch (text.id) {
      case KratosError.PasswordTooSimilarToIdentifier: {
        throw new PasswordTooSimilarToIdentifierError();
      }
      case KratosError.TooManyRequests: {
        throw new TooManyRequestsError();
      }
      case KratosError.InternalServer: {
        throw new GeneralError();
      }
      case KratosError.BadRequest: {
        throw new GeneralError();
      }
      case KratosError.Conflict: {
        throw new LinkExpiredError();
      }
      case KratosError.NotFound: {
        throw new GeneralError();
      }
      case KratosError.Unknown: {
        throw new GeneralError();
      }
      case KratosError.InvalidCredentials: {
        throw new InvalidCredentialsError();
      }
      case KratosError.DuplicateCredentials: {
        throw new DuplicateCredentialsError();
      }
    }
  });
};

const mapUiNodeError = (nodes: Array<UiNode>) => {
  nodes?.forEach((node) => {
    node.messages.forEach((m) => {
      switch (m.id) {
        case KratosError.PasswordTooSimilarToIdentifier: {
          throw new PasswordTooSimilarToIdentifierError();
        }
      }
    });
  });
};
