import Title, { TitleProps } from './Title';
import SvgIcon from '../svg-icon/SvgIcon';
import { useConfig } from '../context/Config';
import cx from 'classnames';
import styles from './Title.module.css';

type TitleWithIconProps = TitleProps & {
  iconName:
    | 'accessTime'
    | 'windowsHello'
    | 'fingerprint'
    | 'sentimentDissatisfied'
    | 'mail';
  iconFillColor?: string;
};

const TitleWithIcon = ({
  type,
  children,
  customStyles,
  textColor,
  iconName,
  iconFillColor,
}: TitleWithIconProps) => {
  const { config } = useConfig();
  return (
    <div className={styles.titleIconWrapper}>
      <SvgIcon
        name={iconName}
        fillColor={iconFillColor || config.theme.colors.brand.text}
        customStyles={styles.icon}
      />
      <Title
        type={type}
        children={children}
        customStyles={cx(styles.withIcon, customStyles)}
        textColor={textColor}
      />
    </div>
  );
};

export default TitleWithIcon;
