import styles from './RecoveryPage.module.css';
import { Button, Link } from '../../components/shared';
import { Trans, useTranslation } from 'react-i18next';
import { useConfig } from '../../components/shared/context/Config';
import TitleWithIcon from '../../components/shared/title/TitleWithIcon';
import { useHistory } from 'react-router';
import BackButtonPlaceholder from '../../components/shared/back-button/BackButtonPlaceholder';
import { useState } from 'react';

const RecoveryError = () => {
  const { config } = useConfig();
  const history = useHistory();
  const { t } = useTranslation(['common', 'recovery_page']);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const redirectToLogin = () => {
    setIsLoading(true);
    history.push('/login');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <BackButtonPlaceholder />
        <TitleWithIcon customStyles={styles.title} iconName={'accessTime'}>
          {t('recovery_page:account-recovery')}
        </TitleWithIcon>
        <p style={{ color: config.theme.colors.brand.text }}>
          <Trans
            ns={'recovery_page'}
            i18nKey="recovery-link-confirmation-error-text"
          >
            The recovery link could not be confirmed. Please request a new link
            using the 'Forgot your password?' function on the{' '}
            <Link customStyles={styles.link} to="/login">
              Login
            </Link>{' '}
            page.
          </Trans>
          <Button
            onClick={redirectToLogin}
            type="button"
            primary
            loading={isLoading}
            customStyles={styles.button}
          >
            {t('common:back-to-login')}
          </Button>
        </p>
      </div>
    </div>
  );
};

export default RecoveryError;
