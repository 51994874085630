import { useEffect, useState } from 'react';
import cx from 'classnames';
import { Button } from '../shared';
import { BurgerButtonProps } from '../../shared/data/types/components';
import styles from './BurgerButton.module.css';

const BurgerButton = ({ onClick, isMenuVisible }: BurgerButtonProps) => {
  const [isCrossActive, setIsCrossActive] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    setIsCrossActive(isMenuVisible);
  }, [isMenuVisible]);

  const handleOnClick = () => {
    setIsCrossActive(!isCrossActive);
    onClick(isCrossActive);
  };

  return (
    <Button
      onClick={handleOnClick}
      noStyle
      customStyles={cx(styles.wrapper, {
        [styles.crossShape]: isCrossActive,
        [styles.burgerShape]: isCrossActive === false,
      })}
    >
      <div className={styles.line} />
      <div className={styles.line} />
      <div className={styles.line} />
    </Button>
  );
};

export default BurgerButton;
