import { useTranslation } from 'react-i18next';
import { Hr } from '../../../components/shared';
import styles from './Select.module.css';
import { NormalizedLoginFlow } from '../../../shared/data/types/kratosFlows';
import Platform from '../platform/Platform';
import SelectButton from '../select-button/SelectButton';
import { useHistory } from 'react-router';
import usePlatformAuthCookie from '../../../shared/utils/hooks/usePlatformAuthCookie';
import { useConfig } from '../../../components/shared/context/Config';
import { useAfterLoginRedirect } from '../../../shared/utils/hooks/useAfterLoginRedirect';

type Props = {
  excludeLoginOption: string;
  loginFlow: NormalizedLoginFlow;
  getUserEmail: () => string;
  successRedirect: (showIntercept: boolean) => void;
};

const Select = ({
  excludeLoginOption,
  loginFlow,
  getUserEmail,
  successRedirect,
}: Props) => {
  const { t } = useTranslation(['sign_in_page', 'common']);
  const { config } = useConfig();
  const history = useHistory();
  const { hasCredentialWithEmail } = usePlatformAuthCookie();
  const { appendQueryParamsTo } = useAfterLoginRedirect();

  let loginOptions = config.appSettings.login.loginOptions.filter(
    (option) => option !== excludeLoginOption
  );

  if (
    loginOptions.includes('platform-auth') &&
    !hasCredentialWithEmail(getUserEmail())
  ) {
    loginOptions = loginOptions.filter((option) => option !== 'platform-auth');
  }

  if (
    loginOptions.includes('passlink') &&
    config.appSettings.login.loginOptions.includes('password')
  ) {
    loginOptions = loginOptions.filter((option) => option !== 'passlink');
  }

  return (
    <>
      {loginOptions.length ? (
        <Hr text={t('common:or')} customStyles={styles.divider} />
      ) : null}
      <ul>
        {loginOptions.includes('platform-auth') ? (
          <li key={0} className={styles.listButton}>
            <Platform
              loginFlow={loginFlow}
              successRedirect={successRedirect}
              getUserEmail={getUserEmail}
            />
          </li>
        ) : null}
        {loginOptions.includes('passlink') ? (
          <li key={1} className={styles.listButton}>
            <SelectButton
              icon={'mail'}
              customStyles={styles.fluidButton}
              buttonStyle
              onClick={() =>
                history.push(appendQueryParamsTo('/login/passlink'))
              }
            >
              {' '}
              {t('passlink')}
            </SelectButton>
          </li>
        ) : null}
        {loginOptions.includes('password') ? (
          <li key={2} className={styles.listButton}>
            <SelectButton
              icon={'password'}
              buttonStyle
              customStyles={styles.fluidButton}
              onClick={() =>
                history.push(appendQueryParamsTo('/login/password'))
              }
            >
              {t('password')}
            </SelectButton>
          </li>
        ) : null}
      </ul>
    </>
  );
};

export default Select;
