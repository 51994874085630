import { ChangeEvent, FocusEvent, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Button, SvgIcon } from '..';
import { Components } from '../../../shared/data/types';

import '../../../shared/styles/colors.module.css';
import styles from './Textbox.module.css';
import { useConfig } from '../context/Config';
import styled from 'styled-components';

const Input = styled.input<{ fontFamily: string; textColor: string }>`
  font-family:  ${(props) => props.fontFamily};
  &:-webkit-autofill, 
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus, 
  &:-webkit-autofill:active, 
  &:-webkit-autofill::first-line {
    color: ${(props) => props.textColor};
    font-size: 1.6rem !important;
    font-weight: 400;
    letter-spacing: 0.05rem;
    font-family: ${(props) => props.fontFamily};
    &:-webkit-box-shadow: 0 0 0 50px white inset;
    &:-webkit-text-fill-color: ${(props) => props.textColor};
  }
`;

const Textbox = ({
  hasButton,
  hideClearButton,
  customStyles = null,
  customStylesWrapper,
  dataTestid,
  defaultValue,
  disabled,
  onLoad,
  error,
  hasFocus,
  label,
  name,
  onChange,
  pattern,
  placeholder,
  radius,
  required,
  maxLength,
  type,
}: Components.TextboxProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const { config } = useConfig();
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (hasFocus || !!error) {
      ref?.current?.select();
    }
  }, [error, hasFocus]);

  useEffect(() => {
    setInputValue(defaultValue || '');
  }, [defaultValue]);

  useEffect(() => {
    if (onLoad && inputValue === '') {
      onLoad(defaultValue || '');
    }
  }, []);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);

    if (onChange) {
      onChange(e);
    }
  };

  const handleClear = () => {
    if (ref.current) {
      ref.current.value = '';
      setInputValue('');
      ref.current.focus();
    }
  };

  const handleInputValidation = (e: FocusEvent<HTMLInputElement>) => {
    const valid = e.target.validity.valid;
    setHasError(inputValue.length > 0 && !valid); // only indicate an error if there is user input
    setFocused(false);
  };

  return (
    <div className={customStylesWrapper}>
      {label ? (
        <label
          htmlFor={dataTestid || name}
          style={{
            color:
              focused && !disabled
                ? config.theme.colors.secondary.light1
                : config.theme.colors.secondary.light3,
          }}
          className={styles.label}
        >
          {label}
        </label>
      ) : null}
      <span className={styles.inputWrapper}>
        <Input
          textColor={config.theme.colors.brand.text}
          fontFamily={config.theme.fontFamily}
          type={type}
          className={cx(styles.input, customStyles, {
            [styles.withButton]: hasButton,
            [styles.readOnly]: disabled,
          })}
          ref={ref}
          id={dataTestid || name}
          pattern={pattern}
          readOnly={disabled}
          required={!!required}
          maxLength={maxLength}
          aria-label={name}
          data-testid={dataTestid || `textbox-${type}-${name}`}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={handleOnChange}
          onBlur={handleInputValidation}
          onFocus={() => setFocused(true)}
          style={{
            color: disabled
              ? config.theme.colors.secondary.light3
              : config.theme.colors.brand.text,
            borderColor:
              error || hasError
                ? config.theme.colors.secondary.negative
                : disabled
                ? config.theme.colors.secondary.light3
                : focused
                ? config.theme.colors.secondary.light1
                : config.theme.colors.secondary.light3,
            borderRadius: radius
              ? `${radius}px`
              : `${config.theme.components.textbox.radius}px`,
          }}
        />

        {!hideClearButton && !disabled && inputValue.length > 0 ? (
          <Button
            onClick={handleClear}
            type="button"
            noStyle
            customStyles={styles.clearButton}
          >
            <SvgIcon
              name="closeCross"
              style={{ width: '14px', height: '14px' }}
              fillColor={config.theme.colors.brand.text}
            />
          </Button>
        ) : null}
        {error ? (
          <SvgIcon
            name="warning"
            fillColor={config.theme?.colors?.secondary?.negative}
          />
        ) : null}
      </span>
      {error ? (
        <div
          className={styles.errorMessage}
          style={{ color: config.theme.colors.secondary.negative }}
        >
          {error}
        </div>
      ) : null}
    </div>
  );
};

Textbox.defaultProps = {
  type: 'text',
};

export default Textbox;
