import styles from './Overlay.module.css';

type OverlayProps = {
  isVisible: boolean;
};

const Overlay = ({ isVisible }: OverlayProps) =>
  isVisible ? <div className={styles.overlay} /> : null;

Overlay.defaultProps = {
  isVisible: false,
};

export default Overlay;
