import { useEffect, useState } from 'react';
import { useMedia } from 'react-media';
import { MEDIA_QUERIES } from '../../shared/utils/mediaQueries';
import { useConfig } from '../shared/context/Config';
import SidebarMobile from './mobile/Sidebar';
import SidebarDesktop from './desktop/Sidebar';

type Props = {
  showBackButton: boolean;
  isRouteRegister: boolean;
};

const Sidebar = (props: Props) => {
  const { config } = useConfig();
  const isSmallScreen = useMedia({ query: MEDIA_QUERIES.tablet });
  const [foregroundColor, setForegroundColor] = useState<string>(
    config.theme.colors.brand.text
  );

  useEffect(() => {
    const color = config.theme.colors.sidebar.background.substring(1); // strip #
    const rgb = parseInt(color, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue
    const lumen = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    setForegroundColor(
      lumen < 200
        ? config.theme.colors.brand.appBackground
        : config.theme.colors.brand.text
    );
  }, []);

  return isSmallScreen ? (
    <SidebarMobile
      hasBackButton={props.showBackButton}
      foregroundColor={foregroundColor}
    />
  ) : (
    <SidebarDesktop
      hasBackButton={props.showBackButton}
      isSidebarListVisible={false}
      foregroundColor={foregroundColor}
    />
  );
};

export default Sidebar;
